import React from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../layouts/Container'
import { Card } from '../../layouts/Card'
import { Inputs } from '../../layouts/Inputs';
import { BlueBtn } from '../../layouts/BlueBtn';


const SetNewPassword = () => {
  return (
    <Container>
      <Card>
        <div  className=''>
    
        <div className='text-center'>
            <img src='./images/keyicon.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl'> Set new password </h1>
            <p className='text-sm md:text-xs px-4'>
            Your new password must be different to previously used passwords.
            </p>
          </div>

          <div>
              <form className='text-sm md:text-xs flex flex-col  mt-8'>
                  <label> Password </label>
                  <Inputs placeholder='*********'
                  type='password'/>

            <label className='mt-4'> Confirm password </label>
                  <Inputs placeholder='*********'
                  type='password'/>

                 
              </form>
          </div>

          
              <BlueBtn className='mt-6 text-sm md:text-xs'> Reset password </BlueBtn>
              <Link to='/signin'>
                 <p className='mt-2 text-center text-sm md:text-xs pb-8'> Back to log in </p>
              </Link>
              
        </div>
      </Card>
    </Container>
  )
}

export default SetNewPassword


