import React from 'react'
import { BlueBtn } from '../layouts/BlueBtn';
import { Link } from 'react-router-dom';
import QuizNav from './QuizNav';
import { QuizContainer } from '../layouts/QuizContainer';
import { QuizCard } from '../layouts/QuizCard';
import { Quizfooter } from '../layouts/Quizfooter';
import scoreData from '../Data/scorefinaldata.json'

const Completed = () => {
  return (

    <div className='h-screen'>
      <QuizNav />
      <QuizContainer >

        <QuizCard>
          <img src='/images/logo.png' alt=''
            className='w-10 h-10 mt-6' />
          <h1 className='font-semibold text-xl'> NFTS </h1>
          <p className='text-sm'> Here’s your overall lesson performance. </p>

          <div className='grid grid-cols-2 gap-2 md:gap-4 mt-8'>
            {scoreData.map(item => (
              <div key={item.id} className='flex flex-row items-center space-x-2 bg-white py-1 px-1 sm:px-4 rounded-md'>
                <img src={item.image} alt='icon'
                  className='h-8 ' />

                <div className='flex flex-col'>
                  <h1 className='font-semibold'> {item.number} </h1>
                  <p className='text-xs -mt-1'> {item.title}</p>
                </div>
              </div>

            ))}
          </div>


          <p className='text-xs text-center mt-10' > Your feedback is critical in helping our Education <br /> Team make improvements to our lessons. </p>
          <div className='flex space-x-4 mt-4 mb-10'>
            <img src='./images/disappointed.png' alt='icons' className='h-4 w-' />
            <img src='./images/sad.png' alt='icons' className='h-4 w-' />
            <img src='./images/teary.png' alt='icons' className='h-4 w-' />
            <img src='./images/happy.png' alt='icons' className='h-4 w-' />
            <img src='./images/excited.png' alt='icons' className='h-4 w-' />
          </div>
        </QuizCard>

      </QuizContainer>

      <div className='md:fixed  mt-8 md:mt-0 md:pt-[4rem] border-t border-gray  md:bottom-0 md:inset-x-0'>
        <Quizfooter>
          <Link to='/welcome'>
            <BlueBtn className=" w-fit px-4 py-1.5 text-sm bg-[#EAECF0] text-black rounded" >
              Back
            </BlueBtn>
          </Link>

          <Link to='/dashboard'>
            <BlueBtn className=" w-fit  px-2 md:px-4 py-1.5 text-sm rounded" >
              Finish
            </BlueBtn>
          </Link>
        </Quizfooter>
      </div>


    </div>

  )
}

export default Completed
