import React, { useState, useRef, useEffect } from 'react';
import { DashboardContainer } from '../layouts/DashboardContainer';
import { useSelector } from 'react-redux';
import { useNotice } from "../Providers/BroadcastProvider";
import { toast } from 'react-toastify';

const Chat = () => {

    const audioRef = useRef();

    const playNotificationSound = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const receiverId = 1;
    const [file, setFile] = useState(null);
    const { message } = useNotice();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }


    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setFile(file);
        setSelectedImage(file);
    };

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);

    const messageContainerRef = useRef(null);

    const generateRandom = () => {
        const min = 100000; // Minimum 6-digit number
        const max = 999999; // Maximum 6-digit number
        const random6DigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        return random6DigitNumber;
    };

    const handleSendMessage = () => {

        let messageId = generateRandom();
        postMessage(messageId)
        if (newMessage.trim() !== '') {
            const newMessageObj = {
                id: messages.length + 1,
                text: newMessage,
                sender: 'user',
            };
            //setMessages([...messages, newMessageObj]);
            setNewMessage('');
        }
    };

    // Scroll to the bottom of the message container when new messages are added
    useEffect(() => {

    }, [messages]);

    const postMessage = async (messageId) => {

        setSendingMessage(true);
        toast.dismiss();
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('receiverId', receiverId);
        formData.append('message', newMessage);
        formData.append('messageId', messageId);
        formData.append('file', file);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/messages`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })

        const response = await request.json();

        if (response.feedback == "success") {
            setSendingMessage(false);
            setFile(null);
            setSelectedImage(null)
            getMessages();
        } else {
            toast.error(
                <>
                    {"An error occurred"}{" "}
                </>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                })
            setSendingMessage(false);
        }

    }

    const getMessages = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/messages?userId=${userId}&receiverId=1`, {
                method: "GET",
                headers,
            })

            const response = await request.json();

            setMessages(response.data)

        } catch (e) {

        }

    }

    useEffect(() => {
        getMessages();
    }, [])

    useEffect(() => {
        if (message) {
            playNotificationSound();
            getMessages();
            toast.dismiss();
            toast.info(
                <>
                    {"New message"}{" "}
                </>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                })
        }
    }, [message])

    const ref = useRef(null);

    // Scroll to the bottom of the message container when new messages are added
    useEffect(() => {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        ref.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }, [messages]);

    return (
        <DashboardContainer style={{ position: 'relative' }}>

            <div className="min-h-screen flex flex-col bg-gray-100 relative">
                <div className="py-4 bg-blue-500 text-white text-center pb-15 pt-15">Chat</div>
                <div
                    ref={messageContainerRef}
                    className="flex-1 overflow-y-auto px-4 pb-1 lg:pb-16 mt-4"
                >
                    <div
                        className={`mb-2 p-2 rounded-lg bg-gray-200 text-gray-800 self-start max-w-md mr-auto`} // Use ml-auto for user messages and mr-auto for other messages
                    >
                        <div>Hello, how can we help you today?</div>
                    </div>
                    {messages.map((message) => (
                        <div
                            key={message.id}
                            className={`mb-2 p-2 rounded-lg ${message.senderUserId === userId
                                ? 'bg-blue-200 text-blue-800 self-end'
                                : 'bg-gray-200 text-gray-800 self-start'
                                } max-w-md ${message.senderUserId === userId ? 'ml-auto' : 'mr-auto'
                                }`} // Use ml-auto for user messages and mr-auto for other messages
                        >
                            <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
                        </div>
                    ))}
                    <div style={{ marginBottom: 30 }} ref={ref} />
                </div>
                <div className='w-full h-30 relative'>
                    <div className="p-4 flex fixed bottom-0 lg:w-[82%] w-full right-0 bg-white">

                        {selectedImage ? (
                            <div className="relative flex-1">
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Selected Image"
                                    className="max-w-md rounded-lg"
                                    style={{ maxHeight: 120 }}
                                />
                                <button
                                    onClick={() => setSelectedImage(null)}
                                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                                >
                                    X
                                </button>
                            </div>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    className="flex-1 p-2 rounded-full border border-gray-300 w-full"
                                    placeholder="Type a message..."
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSendMessage();
                                        }
                                    }}
                                />
                                <label className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-full cursor-pointer">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageUpload}
                                    />
                                    Attach Image
                                </label>
                            </>
                        )}
                        <button
                            className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-full"
                            onClick={handleSendMessage}
                        >
                            Send
                        </button>

                    </div>
                </div>
            </div>
            <audio ref={audioRef}>
                <source src="/alert.mp3" type="audio/mpeg" />
            </audio>
        </DashboardContainer >
    );
};

export default Chat;
