import React from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../layouts/Container'
import { Card } from '../../layouts/Card'
import { BlueBtn } from '../../layouts/BlueBtn';


const Checkmail = () => {
  return (
    <Container>
      <Card>
        <div  className=''>
    
        <div className='text-center'>
            <img src='./images/mail.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl'> Check your email </h1>
            <p className='text-sm md:text-xs px-4'>
              We sent a password reset link to Boyega@moonrepublic.com     
            </p>
          </div>

              <BlueBtn className='mt-6 text-sm md:text-xs'> Open email app </BlueBtn>
              <p className='text-center text-sm md:text-xs mt-4'> Didn’t receive the email?</p>
              <p className='text-center text-sm md:text-xs text-primaryblue'> Click to resend</p>
              <Link to='/signin'>
                 <p className='mt-2 text-center text-sm md:text-xs pb-8'> Back to log in </p>
              </Link>
              
        </div>
      </Card>
    </Container>
  )
}

export default Checkmail


