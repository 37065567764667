import React from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../layouts/Container'
import { Card } from '../../layouts/Card'
import { BlueBtn } from '../../layouts/BlueBtn';


const PasswordResetSuccessfull = () => {
  return (
    <Container>
      <Card>
        <div  className=''>
    
        <div className='text-center'>
            <img src='./images/success.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl'> Password reset </h1>
            <p className='text-sm md:text-xs px-4'>
             Your password has been reset successfully     
            </p>
          </div>

              <BlueBtn className='mt-6 text-sm md:text-xs'> Continue </BlueBtn>
             
              <Link to='/signin'>
                 <p className='mt-2 text-center text-sm md:text-xs pb-8'> Back to log in </p>
              </Link>
              
        </div>
      </Card>
    </Container>
  )
}

export default PasswordResetSuccessfull


