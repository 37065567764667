import React, { useState, useEffect } from 'react';
import copy from 'clipboard-copy';
import Navbar from '../../components/Navbar';
import { DashboardContainer } from '../../layouts/DashboardContainer';
import { NavContainer } from '../../layouts/NavContainer';
import { TopCard } from '../../layouts/TopCard';
import dashboardData from '../../Data/dashboardData.json'
import { LevelCard } from '../../layouts/LevelCard';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiBarChart } from 'react-icons/fi'
import courseData from '../../Data/coursesData.json'
import { RiArrowUpSLine } from 'react-icons/ri'
import { BsCircle, BsDot } from 'react-icons/bs'
import { TbSquareCheckFilled } from 'react-icons/tb';
import { BiUpArrowAlt } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';

ChartJS.register(
  ArcElement, Tooltip, Legend
)

const Dashboard = () => {

  const socket = io(`${process.env.REACT_APP_WEBHOOK_URL}`, {
    withCredentials: true,
    transports: ["websocket", "polling"],
  });

  const dispatch = useDispatch();

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const [selectedItem, setSelectedItem] = useState(courseData[1].id);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingCourses, setPendingCourses] = useState([]);
  const [userPoints, setUserPoints] = useState(null);
  const [userOverallStatus, setUserOverallStatus] = useState([
    { name: "totalCourses", value: "..." },
    { name: "totalCompleted", value: "..." },
    { name: "totalRemaining", value: "..." }
  ]);
  const [referralId, setReferralId] = useState(null);

  const data = {

    datasets: [{
      labels: [
        'Red',
        'Blue',

      ],
      data: [userOverallStatus[0].value, userOverallStatus[1].value],
      backgroundColor: ['#2A6AFF', '#AAC3FF'],
      borderColor: ['#2A6AFF', '#AAC3FF'],
      borderWidth: 0.5,
    }]
  }

  const [copiedText, setCopiedText] = useState('');
  const handleCopyClick = () => {
    const textToCopy = `https://moonrepublic.io/go/${referralId}`;
    copy(textToCopy);
    toast.success('Copied!');
  };

  const getPendingCourses = async () => {

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/pendingCourses/${userId}`, {
        method: "GET",
        headers,
      });

      const response = await request.json();

      if (response.feedback == "success") {
        setPendingCourses(response.data)
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }

  }

  const getUserPoints = async () => {

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/userPoints/${userId}`, {
        method: "GET",
        headers,
      });

      const response = await request.json();

      if (response.feedback == "success") {
        setUserPoints(response.data)
      }

    } catch (e) {
      console.log(e)
    }

  }

  const getOverallStatus = async () => {

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/userOverallStatus/${userId}`, {
        method: "GET",
        headers,
      });

      const response = await request.json();

      if (response.feedback == "success") {
        setUserOverallStatus(response.data)
      }

    } catch (e) {
      console.log(e)
    }

  }

  const getReferralId = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/referralId/${userId}`, {
        method: 'GET',
        headers,
      });
      const response = await res.json();

      if (response.feedback == "error") {
      } else {
        setReferralId(response.data);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getUserPoints();
    getPendingCourses();
    getOverallStatus();
    getReferralId();
    /*socket.on('received message', ({ message }) => {
      // Call the handleReceivedMessage function
      //handleReceivedMessage(message);
      console.log("Hello from the server!");
    });

    // client-side
    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    });

    return () => {
      socket.off('received message');
    };*/
  }, [])

  const [messages, setMessages] = useState([]);
  const [clientId, setClientId] = useState("22"); // Set your client ID here

  useEffect(() => {
    socket.emit("register", clientId);

    socket.on("received message", ({ message }) => {
      // Add the received message to the state
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('message received', ({ message }) => {
      console.log(message);
    });

    return () => {
      socket.off("received message");
    };
  }, [clientId]);

  const handleSendMessage = () => {
    const message = "Hello, server!"; // Your custom message
    socket.emit("send message", { message, clientId });
  };

  const categories = {
    Beginners: {
      value: 'beginner'
    },
    Intermediate: {
      value: 'intermediate'
    },
    Advanced: {
      value: 'advanced'
    }
  };

  return (
    <div className='transition duration-300 ease-in'>
      <DashboardContainer>
        <NavContainer>
          <div>
            <h1 className='font-bold text-2xl hidden md:flex'> Dashboard  </h1>
          </div>

          <Navbar />
        </NavContainer>

        <div>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }
          <div className='flex flex-row  justify-center  lg:justify-between md:gap-5 flex-wrap gap-3'>
            {userOverallStatus.map((item, index) => (
              <TopCard key={index}>

                <p className='mt-3 text-sm text-graytext'>
                  {item.name == "totalRemaining" && "Total Remaining"}
                  {item.name == "totalCompleted" && "Total Completed"}
                  {item.name == "totalCourses" && "Total Courses"}
                </p>
                <div className='flex  items-center justify-between'>
                  <h1 className='mt-2 font-bold text-3xl'> <span className='text-[#9FA2A9] -mr-2'></span> &nbsp;{item.value} </h1>

                </div>

              </TopCard>
            ))}

            <TopCard>


              <p className='mt-3 text-sm text-graytext'> Leaderboard rank </p>
              <div className='md:flex  items-center justify-between'>
                {userPoints ? <h1 className='mt-2 font-bold text-3xl'>{userPoints}</h1> : "Loading..."}
                {/*<p className='mt-2 flex items-center w-fit relative top-[-6.5rem] md:top-0 ml-auto bg-[#ECFDF3] text-[#027A48] font-semibold text-sm sm:text-xs lg:text-sm rounded-full px-2'> <BiUpArrowAlt /> 7040 pts </p>*/}
              </div>

            </TopCard>
          </div>

          <div className='mt-6 md:bg-white   rounded-md '>
            <h1 className='p-4'> Continue reading</h1>

            <div className='flex flex-row    lg:justify-between md:gap-5  gap-3 px-4 pb-4 overflow-x-auto'>
              {pendingCourses.length < 1 &&
                <>
                  <h5 className='text-graytext'>You are not learning any course right now!</h5>
                  <a href="/beginner">
                    <button className="bg-primaryblue rounded-md text-white py-2 w-fit text-xs px-2"> Start learning </button>
                  </a>
                </>
              }
              {pendingCourses.map((item, index) => (
                <a key={index} href={`/view-course?id=${item.courseId}&lessonId=${item.lessonId}&category=${categories[item.categoryName].value}`}>
                  <LevelCard key={index} className=''>
                    <img src={'/images/nfts.png'}
                      alt='blockchain-image'
                      className='rounded-t-xl  h-[8rem] w-full ' />

                    <div className='px-4'>
                      <p className='mt-4 font-bold  text-sm'> {item.title} </p>
                      <div className='flex justify-between items-center mt-3 '>
                        {/* Conditionally set the background and text color based on item.level */}
                        <p
                          className={`px-1 py-1 text-xs rounded-full font-semibold flex  items-center  ${item.categoryName === 'Beginners'
                            ? ' text-[#027A48]'
                            : item.categoryName === 'Intermediate'
                              ? ' text-[#F79009]'
                              : item.categoryName === 'Advanced'
                                ? ' text-[#F04438]'
                                : ''
                            }`}
                        >
                          <FiBarChart className='mr-1' /> {item.categoryName}
                        </p>
                        <p className='flex items-center text-xs'> <span> <img src='./images/loading.png' alt='icon-loading' /> </span> {item.length}mins </p>
                      </div>
                    </div>
                  </LevelCard>
                </a>
              ))}
            </div>
          </div>

          <div className='flex md:flex-row mr-10 flex-col md:p-0 p-4 mt-4 '>
            {/* 
            <div className='md:w-[35%] w-full bg-white p-4 rounded-lg'>
              <h1 className='font-bold'>  Course progress</h1>
              <div>

                <div className='flex bg-[#F5F8FF] rounded-lg p-1 items-center space-x-1 mt-4 hover:cursor-pointer mb-4'>
                  <div>
                    <RiArrowUpSLine />
                  </div>

                  <div className='flex justify-between w-full items-center'>
                    <div className=''>
                      <h1 className='truncate font-semibold w-[99%] md:w-full'> NFTs </h1>
                      <p className=' text-xs font-semibold flex items-center'> 7 lectures <BsDot /> 1 hr </p>
                    </div>

                    <img className='h-3' src='./images/loading.png' />
                  </div>
                </div>

                <div className=' space-y-4'>

                  <div className='flex flex-row items-center justify-between'>
                    <div className='mb-2 flex flex-row space-x-2 items-center'>
                      <TbSquareCheckFilled className='text-xs  text-green-700' />
                      <p className='text-[0.9rem] w-[10rem] truncate  text-[#D0D5DD]' > NFT use cases </p>
                    </div>

                    <div>
                      <p className='text-xs text-[#D0D5DD]'> 02:26 </p>
                    </div>
                  </div>

                  <div className='flex flex-row items-center justify-between'>
                    <div className='mb-2 flex flex-row space-x-2 items-center'>
                      <TbSquareCheckFilled className='text-xs text-green-700' />
                      <p className='text-[0.9rem] w-[10rem] truncate text-[#D0D5DD] ' > The Different blockchains </p>
                    </div>

                    <div>
                      <p className='text-xs text-[#D0D5DD]'> 02:26 </p>
                    </div>
                  </div>


                  <div className='flex flex-row items-center justify-between'>
                    <div className='mb-2 flex flex-row space-x-2 items-center'>
                      <img src='./images/loading.png' alt='' className='h-3' />
                      <p className='text-[0.9rem] w-[10rem] truncate ' > Setting up and securing </p>
                    </div>

                    <div>
                      <p className='text-xs'> 02:26 </p>
                    </div>
                  </div>


                  <div className='flex flex-row items-center justify-between'>
                    <div className='mb-2 flex flex-row space-x-2 items-center'>
                      <BsCircle className='h-3 text-graytext' />
                      <p className='text-[0.9rem] w-[10rem] truncate ' > NFT scams and safety & lessons </p>
                    </div>

                    <div>
                      <p className='text-xs'> 02:26 </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
*/}

            <div className='md:w-[30%] w-full mx-auto lg:mx-0 bg-white mt-6 md:mt-0  p-4 rounded-lg'>
              <div className='relative sm:h-[12rem] sm:w-[12rem] w-[13rem] h-[13rem] mx-auto'>
                <Doughnut
                  data={data}
                  className=''
                ></Doughnut>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <h1 className='text-center text-sm'>Overall<br />Progress</h1>
                </div>
              </div>

              <div className='flex items-center justify-between mt-2'>
                <div className='flex flex-row items-center space-x-3'>
                  <img src='./images/purple.png'
                    alt='icon'
                    className='h-8 w-1' />

                  <div className=' text'>
                    <h1 className='font-bold'> {userOverallStatus[0].value}</h1>
                    <p className='text-sm text-graytext'> Total course</p>
                  </div>
                </div>

                <div className='flex flex-row items-center space-x-3'>
                  <img src='./images/blue.png'
                    alt='icon'
                    className='h-8 w-1' />

                  <div className=' text'>
                    <h1 className='font-bold'> {userOverallStatus[1].value} </h1>
                    <p className='text-sm text-graytext'> Completed course</p>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;
            &nbsp;
            <div className='md:w-[32%] w-full bg-white  p-4 mt-6 md:mt-0 rounded-lg'>
              <div>
                <h1 className='text-base font-bold'> Spread the word and earn rewards </h1>
                <p className='text-xs text-smeibold mt-4 text-graytext'> When someone signs up using your unique referral link,
                  you and the reffered user will earn $5 worth of Moonrepyblic
                  token after the user successfully signs up</p>
              </div>

              <div className=' mx-auto flex justify-between border border-gray lg:w-fit lg:min-w-[13rem] max-w-[20rem] py-1 rounded-md px-2 items-center w-[95%] overflow-hidden mt-4'>
                <p className='text-xs overflow-hidden '>{referralId ? `https://moonrepublic.io/go/${referralId}` : '...'} </p>
                <button
                  className="bg-primaryblue text-white  text-xs py-1 ml-4  px-4 rounded-md"
                  onClick={handleCopyClick}
                >
                  Copy
                </button>

              </div>
              <ToastContainer />

              <div className='flex w-fit mx-auto items-center mt-6'>
                <div className='flex flex-col items-center'>
                  <img className='h-7 w-7' src='./images/share.png' alt='sharelinkicon' />
                  <p className=' mt-1 font-semibold text-xs text-center'> Share link</p>
                </div>

                <img src='./images/connector.png' className='-mt-6' />

                <div className='flex flex-col items-center'>
                  <img className='h-7 w-7' src='./images/friends.png' alt='sharelinkicon' />
                  <p className=' mt-1 font-semibold text-xs text-center'> Friends joins</p>
                </div>

                <img src='./images/connector.png ' className='-mt-6' />

                <div className='flex flex-col items-center'>
                  <img className='h-7 w-7' src='./images/rewards.png' alt='sharelinkicon' />
                  <p className=' mt-1 font-semibold text-xs text-center'> Earn rewards</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </DashboardContainer >
    </div >
  );
};

export default Dashboard;
