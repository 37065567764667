import React, { useEffect, useState } from "react";
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { AiOutlineLeft } from 'react-icons/ai'

//import { parseUnits } from "viem";

function TokenTransfer() {

    useEffect(() => {

    }, [])

    return (
        <DashboardContainer>
            <NavContainer>
                <div>
                    <h1 className='font-bold text-2xl hidden lg:flex'> Checkout  </h1>
                </div>

                <Navbar />
            </NavContainer>


            <div className='flex space-x-4 items-center md:mt-8 hover:cursor-pointer'>
                <AiOutlineLeft />
                <h1 onClick={() => { window.history.back() }}> Back </h1>
            </div>
        
        </DashboardContainer>
    );
}

export default TokenTransfer;
