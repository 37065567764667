import './index.css';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate, Outlet } from 'react-router-dom';
import {
  Signup, SignIn, Forgotpassword, Checkmail, SetNewPassword, PasswordResetSuccessfull, EmailVerification, FinalizingSetup
} from './Pages/Authpages';

import { Home, ViewCourse, Intermediate, Advanced, Beginners, Quiz, Dashboard, Leaderboard, Settings, Support, Progress, Earnings, Quizzes, Cardcheckout, Cryptocheckout, BeginnersCourse, Intermediate_preview } from './Pages/DashboardPages';
import BottomNav from './components/BottomNav';
import Completed from './components/Completed';
import Sidebar from './components/Sidebar';
import './fonts/GeneralSans-Regular.woff'
import LandingPage from './Pages/Landingpage/Pages/Waitlist'
import './fonts/FontSpring1.otf'
import './fonts/FontSpring2.otf'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import SearchResult from './Pages/DashboardPages/SearchResult';
import Go from './Pages/Authpages/go';
import TokenTransfer from './Pages/DashboardPages/TokenTransfer';
import Chat from './components/Chat';
import CryptoPay from './Pages/DashboardPages/cryptopay';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const loggedIn = useSelector((state) => {
    try {
      if (state.userAuth.userAuth.loggedIn) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
      console.log(e)
    }
  })

  function ProtectedRoute({ loggedIn }) {
    const navigate = useNavigate();

    useEffect(() => {
      if (!loggedIn) {
        navigate('/');
      }
    }, [loggedIn, navigate]);

    return (
      <>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/view-course" element={<ViewCourse />} />
          <Route path="/progress" element={<Progress />} />
          <Route path="/beginner" element={<Beginners />} />
          <Route path="/beginner-preview" element={<BeginnersCourse />} />
          <Route path="/intermediate" element={<Intermediate />} />
          <Route path="/intermediate_preview" element={<Intermediate_preview />} />
          <Route path="/advanced" element={<Advanced />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/my-earnings" element={<Earnings />} />
          <Route path="/welcome" element={<Quizzes />} />
          <Route path="/support" element={<Support />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/cardcheckout" element={<Cardcheckout />} />
          <Route path="/cryptocheckout" element={<Cryptocheckout />} />
          <Route path="/completed" element={<Completed />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/tokentransfer" element={<TokenTransfer />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/cryptopay/:invoiceId" element={<CryptoPay />} />
        </Routes>
      </>
    );
  }

  // Function to check if the current route should hide the sidebar
  const shouldHideSidebar = () => {
    const hiddenRoutes = [
      '/',
      '/signin',
      '/signup',
      '/forgotpassword',
      '/check-your-mail',
      '/set-new-password',
      '/password-reset-successfull',
      '/email-verification',
      '/finalizing-setup',
      '/welcome',
      '/completed',
      '/questions',
      '/go/:referralId',
      '/quiz'
    ];

    // Regular expression pattern for '/go/:referralId'
    const goRoutePattern = /^\/go\/[a-zA-Z0-9_-]+$/;

    // Check if the current location matches any of the hidden routes
    const isHidden = hiddenRoutes.some((route) => route === location.pathname);

    // Check if the current location matches the '/go/:referralId' pattern
    const isGoRoute = goRoutePattern.test(location.pathname);

    return isHidden || isGoRoute;
    return hiddenRoutes.includes(location.pathname);
  };

  return (
    <div className='lg:flex '>
      {!shouldHideSidebar() && <Sidebar />}
      {!shouldHideSidebar() && location.pathname != "/chat" && <BottomNav />}

      <div className='w-full bg-dashboardbg  font-generalsans'>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/check-your-mail" element={<Checkmail />} />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          <Route path="/password-reset-successfull" element={<PasswordResetSuccessfull />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/finalizing-setup" element={<FinalizingSetup />} />
          <Route path="/go/:referralId" element={<Go />} />

          <Route path="/*" element={<ProtectedRoute loggedIn={loggedIn} />}>
          </Route>
        </Routes>
      </div>
      <ToastContainer />
    </div>

  );
}

export default App;
