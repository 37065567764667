import React, { useState, useEffect } from 'react';

function CountdownTimer({ targetTimestamp,onCountdownEnd }) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(targetTimestamp));

  // Calculate the time remaining between the target timestamp and the current time
  function calculateTimeRemaining(target) {
    const currentTime = new Date().getTime();
    const timeDifference = target - currentTime;
    const secondsRemaining = Math.max(Math.floor(timeDifference / 1000), 0);

    return secondsRemaining;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTimeRemaining = calculateTimeRemaining(targetTimestamp);

      if (updatedTimeRemaining <= 0) {
        clearInterval(intervalId);
        if (onCountdownEnd) {
          onCountdownEnd();
        }
      } else {
        setTimeRemaining(updatedTimeRemaining);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetTimestamp, onCountdownEnd]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div>
      <p>Time Remaining: {formatTime(timeRemaining)}</p>
    </div>
  );
}

export default CountdownTimer;
