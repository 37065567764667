import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '../../layouts/Container'
import { Card } from '../../layouts/Card'
import { Inputs } from '../../layouts/Inputs';
import { BlueBtn } from '../../layouts/BlueBtn';
import { FcGoogle } from 'react-icons/fc'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, setRegistered } from '../../actions/userActions';
import { useGoogleLogin } from '@react-oauth/google';

const Signup = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFormSubmit = () => {

    if (!firstName || !lastName || !email) {
      toast.error('All fields are required',
        {
          position: "top-center"
        });
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Invalid email format', {
        position: "top-center"
      });
      return;
    }

    const fullName = `${firstName} ${lastName}`;

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        requestKey: `${process.env.REACT_APP_SERVER_KEY}`,
        fullName: fullName,
        email: email,
      }),
    })
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response.ok) {
          return response.json();
        } else {
          toast.error('Network response was not ok', { position: "top-center" });
        }
      })
      .then((data) => {
        console.log(data)
        if (data.feedback == "success") {
          dispatch(setRegistered(true));
          dispatch(registerUser(email))
          toast.success('Verification code has been sent to you!', { position: "top-center" });
          navigate('/email-verification');
        }
        if (data.feedback == "completed") {
          toast.success('You have already registered! please sign in', { position: "top-center" });
          navigate('/signin');
        }
        if (data.feedback == "verified") {
          dispatch(setRegistered(true));
          dispatch(registerUser(email))
          toast.success('Setup your username and password to complete registration!', { position: "top-center" });
          navigate('/finalizing-setup');
        }
        if (data.feedback == "exists") {
          dispatch(setRegistered(true));
          dispatch(registerUser(email))
          toast.error(`${data.message}`, { position: "top-center" });
          navigate('/email-verification')
        }
      })
      .catch((error) => {
        toast.error('There was a problem registering the user', { position: "top-center" });
      });
  };

  const signupWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse)
    },
    onError: error => {
      console.log('Login Failed' + error);
    }
  });

  return (
    <Container>
      <Card>
        <div className=''>
          <div className='text-center'>
            <img src='./images/logo.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl mt-1'> Create a free account </h1>
            <p className='text-sm md:text-xs'>
              Already have an account?{' '}
              <Link to='/signin' className='underline font-semibold text-primaryblue'>
                Sign in
              </Link>
            </p>
          </div>

          <div>
            <form className='text-sm md:text-xs flex flex-col mt-6'>
              <div className='flex flex-col md:flex-row  justify-between '>

                <div className='flex flex-col md:w-[48%]'>
                  <label> First Name</label>
                  <Inputs className='' placeholder='Firstname' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>

                <div className=' mt-2 md:mt-0 flex flex-col md:w-[48%]'>
                  <label> Last Name</label>
                  <Inputs className='' placeholder='Lastname' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
              </div>

              <label className='mt-4'> Email </label>
              <Inputs placeholder='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />


            </form>
          </div>

          <div>
            <BlueBtn className='mt-4 text-sm md:text-xs' onClick={() => {
              if (!isLoading) {
                handleFormSubmit();
              }
            }}>
              {isLoading &&
                <span>Loading...</span>
              }
              {!isLoading &&
                <span>Sign up</span>
              }
            </BlueBtn>
            <BlueBtn className='mt-2 text-sm md:text-xs border border-gray bg-transparent text-black flex  justify-center items-center space-x-2' onClick={() => {
              signupWithGoogle();
            }}> <span className='mr-1' > <FcGoogle /> </span> Sign in with Google  </BlueBtn>
            <p className='text-sm md:text-[0.65rem] text-center mt-2 pb-10'> By signing up, you agree to our Terms of Use and Privacy Policy </p>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default Signup

