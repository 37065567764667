import React, { useState, useEffect, useRef } from 'react'
import { BlueBtn } from '../../layouts/BlueBtn';
import { Link } from 'react-router-dom';
import QuizNav from '../../components/QuizNav';
import { QuizContainer } from '../../layouts/QuizContainer';
import { QuizCard } from '../../layouts/QuizCard';
import { Quizfooter } from '../../layouts/Quizfooter';
import { useSelector } from 'react-redux';

const Quiz = () => {

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const query = new URLSearchParams(window.location.search);

  const realm = query.get('realm');
  const realmId = query.get('realmId');
  const quizId = query.get('quizId');
  const data = query.get('data').split('-');

  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizStarted, setQuizStarted] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [points, setPoints] = useState(0);

  const loadQuiz = async () => {

    setIsLoading(true);

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/loadQuiz/${quizId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const response = await request.json();

      setQuestions(response.data.questions);

    } catch (e) {

    }

  }

  const setAnswer = (option) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestion] = option;
    setSelectedAnswers(updatedAnswers);
  }

  useEffect(() => {
    loadQuiz();
  }, [])

  useEffect(() => {
    //console.log(questions[currentQuestion])
  }, [questions])

  useEffect(() => {
    //console.log(selectedAnswers)
  }, [selectedAnswers])

  const finish = () => {
    for (let a = 0; a < questions.length; a++) {
      if (questions[a].answer == selectedAnswers[a]) {
        console.log("correct")
      } else {
        console.log("incorrect")
      }
    }
  }

  const completeQuiz = async (points) => {
    console.log("Complete quiz")

    const request = await fetch(`${process.env.REACT_APP_API_URL}/completeQuiz`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        userId: userId,
        points,
      })
    })

    const response = await request.json();

  }

  return (

    <div className={`${!quizCompleted ? 'h-screen' : ''}`}>
      <QuizNav />
      {!quizStarted && !quizCompleted &&
        <>
          <QuizContainer >
            <QuizCard>
              <img src='./images/quizmark.png' alt=''
                className='h-10 w-10 mt-[9rem] ' />
              <h1 className='font-bold'> Let’s do some exercises </h1>
              {questions.length > 0 &&
                <p className='mb-[9rem] text-sm'> {questions.length} questions </p>
              }
            </QuizCard>
          </QuizContainer>

          <div className='md:fixed  mt-8 md:mt-0 md:pt-[4rem] border-t border-gray  md:bottom-0 md:inset-x-0'>
            <Quizfooter>
              <Link to='/dashboard'>
                <BlueBtn className=" w-fit px-4 py-1.5 text-xs bg-[#EAECF0] text-black rounded" >
                  Back
                </BlueBtn>
              </Link>

              <BlueBtn className=" w-fit  px-2 py-1.5 text-xs rounded" onClick={() => { setQuizStarted(true) }}>
                Start now
              </BlueBtn>

            </Quizfooter>
          </div>
        </>
      }

      {quizStarted &&
        <>
          <QuizContainer >
            <QuizCard className='p-6'>

              <h1 className='text-sm'> Question {currentQuestion + 1} of {questions.length} </h1>

              <p className='text-sm font-semibold'> {questions[currentQuestion].question} </p>

              <div onClick={() => {
                setAnswer('option1');
              }}>
                <p className={`w-[90%] text-center mt-6 mx-auto p-2 md:p-4 border ${selectedAnswers[currentQuestion] === 'option1' ? 'border-green-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                  {questions[currentQuestion].option1}
                </p>
              </div>

              <div onClick={() => {
                setAnswer('option2');
              }}>
                <p className={`w-[90%] text-center mt-3 mx-auto p-2 md:p-4 border ${selectedAnswers[currentQuestion] === 'option2' ? 'border-green-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                  {questions[currentQuestion].option2}
                </p>
              </div>

              <div onClick={() => {
                setAnswer('option3');
              }}>
                <p className={`w-[90%] text-center mx-auto mt-3 p-2 md:p-4 border ${selectedAnswers[currentQuestion] === 'option3' ? 'border-green-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                  {questions[currentQuestion].option3}
                </p>
              </div>

              <div onClick={() => {
                setAnswer('option4');
              }}>
                <p className={`w-[90%] text-center mx-auto mt-3 p-2 md:p-4 border ${selectedAnswers[currentQuestion] === 'option4' ? 'border-green-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                  {questions[currentQuestion].option4}
                </p>
              </div>

            </QuizCard>
          </QuizContainer>


          <div className=' md:fixed  mt-8 md:mt-0 md:pt-[4rem] border-t border-gray  md:bottom-0 md:inset-x-0 '>
            <Quizfooter>

              <BlueBtn className=" w-fit px-4 py-1.5 text-xs bg-[#EAECF0] text-black rounded" onClick={() => {
                if (currentQuestion > 0) {
                  setCurrentQuestion(currentQuestion - 1)
                } else {
                  setQuizStarted(false)
                }
              }}>
                Back
              </BlueBtn>

              <BlueBtn className=" w-fit  px-2 py-1.5 text-xs rounded" onClick={() => {
                if (currentQuestion < questions.length - 1) {
                  setCurrentQuestion(currentQuestion + 1)
                } else {
                  finish()
                  setQuizCompleted(true)
                  setQuizStarted(false);

                  let correctAnswers = 0;
                  let wrongAnswers = 0;

                  questions.map((question, index) => {
                    //console.log("Chosen option=>"+selectedAnswers[index]+"Correct option=>"+question.answer)
                    if (selectedAnswers[index] == question.answer) {
                      console.log("correct")
                      correctAnswers++;
                      setCorrectAnswers(correctAnswers)
                    } else {
                      console.log("incorrect")
                      wrongAnswers++;
                      setWrongAnswers(wrongAnswers)
                    }
                  })
                  if(correctAnswers>0){
                    console.log(correctAnswers*2)
                    completeQuiz(correctAnswers*2);
                  }
                }
              }}>
                Continue
              </BlueBtn>

            </Quizfooter>
          </div>
        </>
      }

      {quizCompleted &&
        <>
          <QuizContainer >
            <QuizCard>
              <img src='/images/logo.png' alt=''
                className='w-10 h-10 mt-6' />
              <h1 className='font-semibold text-xl'> Quiz Overview </h1>
              <p className='text-sm'> Here’s your overall quiz performance. </p>

              <div className='grid grid-cols-2 gap-2 md:gap-4 mt-8'>

                <div className='flex flex-row items-center space-x-2 bg-white py-1 px-1 sm:px-4 rounded-md'>
                  <img src="images/timespent.png" alt='icon'
                    className='h-8 ' />

                  <div className='flex flex-col'>
                    <h1 className='font-semibold'>{correctAnswers}</h1>
                    <p className='text-xs -mt-1'>Correct Answer</p>
                  </div>

                </div>

                <div className='flex flex-row items-center space-x-2 bg-white py-1 px-1 sm:px-4 rounded-md'>
                  <img src="images/failed.png" alt='icon'
                    className='h-8 ' />

                  <div className='flex flex-col'>
                    <h1 className='font-semibold'>{wrongAnswers}</h1>
                    <p className='text-xs -mt-1'>Failed Answer</p>
                  </div>

                </div>

              </div>

              <p className='text-xs text-center mt-10' >
              </p>
              <div>
                <a href={`/view-course?id=${data[0]}&lessonId=${data[1]}&category=${data[2]}&from=quiz`} >
                  <BlueBtn className=" w-fit  px-2 md:px-4 py-1.5 text-sm rounded mb-10 mr-3" >
                    Finish
                  </BlueBtn>
                </a>

                <BlueBtn className=" w-fit  px-2 md:px-4 py-1.5 text-sm rounded mb-10" onClick={() => {
                  setQuizCompleted(false);
                  setCurrentQuestion(0);
                  setSelectedAnswers([]);
                }} >
                  Try again
                </BlueBtn>
              </div>
            </QuizCard>
            {questions.map((question, index) => {

              return (
                <QuizCard key={index} className='p-6'>

                  <p className='text-sm font-semibold'> {question.question} </p>

                  <div>
                    <p className={`w-[90%] text-center mt-6 mx-auto p-2 md:p-4 border ${question.answer === 'option1' ? 'border-green-500' : ''} ${selectedAnswers[index] === 'option1' && question.answer != 'option1' ? 'border-red-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                      {question.option1}
                    </p>
                  </div>

                  <div>
                    <p className={`w-[90%] text-center mt-3 mx-auto p-2 md:p-4 border ${question.answer === 'option2' ? 'border-green-500' : ''} ${selectedAnswers[index] === 'option2' && question.answer != 'option2' ? 'border-red-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                      {question.option2}
                    </p>
                  </div>

                  <div>
                    <p className={`w-[90%] text-center mx-auto mt-3 p-2 md:p-4 border ${question.answer === 'option3' ? 'border-green-500' : ''} ${selectedAnswers[index] === 'option3' && question.answer != 'option3' ? 'border-red-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                      {question.option3}
                    </p>
                  </div>

                  <div>
                    <p className={`w-[90%] text-center mx-auto mt-3 p-2 md:p-4 border ${question.answer === 'option4' ? 'border-green-500' : 'border-gray'} ${selectedAnswers[index] != 'option4' && question.answer == 'option4' ? 'border-red-500' : 'border-gray'} text-sm bg-[#F2F4F7] rounded hover:cursor-pointer`}>
                      {question.option4}
                    </p>
                  </div>

                </QuizCard>
              )
            })}
            <br />
          </QuizContainer>

        </>
      }

    </div >

  )
}

export default Quiz
