import tw from "tailwind-styled-components"


export const SettingsBtn = tw.div`
 ${({ color }) => `
    background-color: ${color};
    hover:bg-${color};
  `}

py-2
 
w-fit
text-sm
hover:cursor-pointer
hover:text-primaryblue
transition
duration-300
ease-in
font-semibold
`;


