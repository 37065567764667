import tw from "tailwind-styled-components"


export const BlueBtn = tw.button `
bg-primaryblue
w-full
rounded-md
text-white
py-2
text-sm
`