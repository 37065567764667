import tw from "tailwind-styled-components"


export const QuizContainer = tw.div `
container
mx-auto 
md:px-10
px-6   
xl:max-w-[1180px]
mt-6
`