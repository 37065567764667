import { useState } from 'react';
import { TiTick } from 'react-icons/ti';

const Checkbox = ({checked,onChange}) => {
    const [isChecked, setIsChecked] = useState(false);
  
    const toggleCheckbox = () => {
      setIsChecked(!isChecked);
    };
  
    return (
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="form-checkbox h-2.5 w-2.5 text-blue-500 rounded focus:ring-blue-500"
        />
      </label>
    );
  };

  
  export default Checkbox