// src/reducers/userReducer.js

const initialState = {
    email: '',
    registered: false,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'REGISTER_USER':
        return { ...state, email: action.payload };
      case 'SET_REGISTERED':
        return { ...state, registered: action.payload };
      default:
        return state;
    }
  };
  
  export default userReducer;
  