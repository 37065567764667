import React, { useEffect, useState } from 'react'
import { RiSearchLine } from 'react-icons/ri'
import { BsPersonCircle } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const Navbar = () => {

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const [userImage, setUserImage] = useState(null)
  const [loading, setLoading] = useState(true);
  const [userPoints, setUserPoints] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const getUserPoints = async () => {

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/userPoints/${userId}`, {
        method: "GET",
        headers,
      });

      const response = await request.json();

      if (response.feedback == "success") {
        setUserPoints(response.data)
      }

    } catch (e) {
      console.log(e)
    }

  }

  const getUserImage = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/userImage/${userId}`, {
        method: 'GET',
        headers,
      });
      const response = await res.json();
      setUserImage(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const search = async()=>{

    if(!searchKeyword){
      toast.error("Search keyword cannot be empty",{
        position:"top-center"
      })
      return;
    }

    window.location.href = "/search-result?keyword="+searchKeyword

  }

  useEffect(() => {
    getUserImage();
    getUserPoints();
  }, [])

  return (
    <div className='flex flex-row md:w-[80%] xl:w-[85%]  items-center'>
      <div className="pt-2 relative mx-auto text-gray-600 hidden lg:flex">
        <input className="border-2 border-gray bg-white h-9 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="search" name="search" placeholder="search by course title" value={searchKeyword} onChange={(event)=>{
            setSearchKeyword(event.target.value)
          }}  />
        <button type="submit" className="absolute right-0 top-0 mt-5 mr-4 " onClick={search}>
          <RiSearchLine />
        </button>
      </div>

      <div className=' justify-end  h-fit items-center  space-x-5 hidden lg:flex'>
        <div className='flex items-center space-x-2'>
          <img src='./images/achieve.png' alt='icons' />
          <h1>{userPoints ? `${userPoints}Pts` : "..."} </h1>
        </div>
        {!loading && !userImage &&
          <BsPersonCircle style={{ fontSize: 40, color: "blue" }} />
        }
        {!loading && userImage &&
          <img className=' h-8 w-8' src={userImage} alt='' style={{ borderRadius: '50%' }} />
        }
      </div>
    </div>
  )
}

export default Navbar