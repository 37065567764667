import React, { useState, useEffect } from 'react';
import { DashboardContainer } from '../../layouts/DashboardContainer'
import Navbar from '../../components/Navbar'
import { NavContainer } from '../../layouts/NavContainer';
import { FiBarChart, FiBook } from 'react-icons/fi';
import { Button } from '../../layouts/Button';
import WeeklyLeaderboard from '../../components/WeeklyLeaderboard';
import MonthlyLeaderboard from '../../components/MonthlyLeaderboard';
import OverallLeaderBboard from '../../components/OverallLeaderBboard';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Leaderboard = () => {

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const [activeTab, setActiveTab] = useState('Weekly');
  const openTab = (event, tabName) => {
    setActiveTab(tabName);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [allLeaderBboard, setAllLeaderBboard] = useState([]);

  const getUser = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/leaderboard`, {
        method: 'GET',
        headers,
      });
      const response = await res.json();

      if (response.feedback == "error") {
        toast.error(response.message, {
          position: "top-center"
        });
        setIsLoading(false);
      } else {
        setAllLeaderBboard(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred", {
        position: "top-center"
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUser();
  }, [])

  return (
    <DashboardContainer>
      <NavContainer>
        <div>
          <h1 className='font-bold text-2xl ml-2 lg:ml-0 hidden md:flex'> Leaderboard </h1>
        </div>

        <Navbar />
      </NavContainer >

      <div className='flex sm:flex-row flex-col sm:justify-between md:mt-6 md:items-center'>
        <div>
          <h1 className='font-bold '> Rankings </h1>
        </div>

        <div className='  mt-2 py-0.5 sm:mt-0 rounded-full px-1 flex justify-between space-x-2 md:space-x-4 border border-gray text-sm'>
          {/* <button className='rounded-full border px-1.5 bg-primaryblue'> Weekly  </button>
          <button> Monthly  </button>
          <button> Overall  </button> */}

          <Button
            className={`tablinks   ${activeTab === 'Weekly' ? 'active bg-[#EAF0FF] font-semibold text-primaryblue' : ''
              }`}
            onClick={(event) => openTab(event, 'Weekly')}
          >
            Weekly
          </Button>

          <Button
            className={`tablinks   ${activeTab === 'Monthly' ? 'active  bg-[#EAF0FF] font-semibold text-primaryblue' : ''
              }`}
            onClick={(event) => openTab(event, 'Monthly')}
          >
            Monthly
          </Button>

          <Button
            className={`tablinks   ${activeTab === 'Overall' ? 'active  bg-[#EAF0FF] font-semibold text-primaryblue' : ''
              }`}
            onClick={(event) => openTab(event, 'Overall')}
          >
            Overall
          </ Button>

        </div>
      </div>

      {/*
      <div className='flex lg:flex-row flex-col justify-between items-center'>
        <div className='  mt-4  w-full mx-auto lg:mx-0 lg:w-[16rem] xl:w-[18rem] p-3 rounded-md flex flex-col  bg-[#EAF0FF] hover:cursor-pointer hover:bg-[#EAF0FF]'>

          <div className='  '>
            <img className='h-6 w-6 ' src='./images/third.png' alt='icon' />
          </div>

          <div className='flex flex-row justify-between'>
            <h1 className='font-semibold '> Cameron Williamson </h1>
            <p className='text-xs'> 8050 pts </p>

          </div>


          <div className='flex flex-row justify-between'>
            <p className='flex items-center text-xs'> <FiBook className='mr-1' /> 6 Courses </p>
            <p
              className="px-1 text-[#F97066] py-1 text-xs rounded-full font-semibold flex  items-center"
            >
              <FiBarChart className='mr-1' /> Advanced
            </p>


          </div>




        </div>

        <div className='  mt-4  w-full mx-auto lg:mx-0 lg:w-[16rem] xl:w-[18rem] p-3 rounded-md flex flex-col  bg-white hover:cursor-pointer hover:bg-[#EAF0FF]'>

          <div className='  '>
            <img className='h-6 w-6 ' src='./images/firsticon.png' alt='icon' />
          </div>

          <div className='flex flex-row justify-between'>
            <h1 className='font-semibold '> Cameron Williamson </h1>
            <p className='text-xs'> 8050 pts </p>

          </div>


          <div className='flex flex-row justify-between'>
            <p className='flex items-center text-xs'> <FiBook className='mr-1' /> 6 Courses </p>
            <p
              className="px-1 text-[#F97066] py-1 text-xs rounded-full font-semibold flex  items-center"
            >
              <FiBarChart className='mr-1' /> Advanced
            </p>


          </div>




        </div>

        <div className='  mt-4  w-full mx-auto lg:mx-0 lg:w-[16rem] xl:w-[18rem] p-3 rounded-md flex flex-col  bg-white hover:cursor-pointer hover:bg-[#EAF0FF]'>

          <div className='  '>
            <img className='h-6 w-6 ' src='./images/secondicon.png' alt='icon' />
          </div>

          <div className='flex flex-row justify-between'>
            <h1 className='font-semibold '> Robert Fox </h1>
            <p className='text-xs'> 7826 pts </p>

          </div>


          <div className='flex flex-row justify-between'>
            <p className='flex items-center text-xs'> <FiBook className='mr-1' /> 6 Courses </p>
            <p
              className="px-1 text-[#F79009] py-1 text-xs rounded-full font-semibold flex  items-center"
            >
              <FiBarChart className='mr-1' /> Intermediate
            </p>


          </div>




        </div>
      </div>
            */}

      {isLoading &&
        <div>
          <span className="loader"></span>
        </div>
      }
      {!isLoading &&
        <>
          <div id="Weekly"
            className={`tabcontent  ${activeTab === 'Weekly' ? 'block' : 'hidden'
              }`}
          >
            <OverallLeaderBboard allLeaderBboard={allLeaderBboard} />
          </div>


          <div id="Monthly"
            className={`tabcontent  ${activeTab === 'Monthly' ? 'block' : 'hidden'
              }`}
          >
            <OverallLeaderBboard allLeaderBboard={allLeaderBboard} />
          </div>

          <div id="Overall"
            className={`tabcontent  ${activeTab === 'Overall' ? 'block' : 'hidden'
              }`}
          >
            <OverallLeaderBboard allLeaderBboard={allLeaderBboard} />
          </div>
        </>
      }
    </DashboardContainer>
  )
}

export default Leaderboard
