import React, { useState } from 'react'
import { HomeContainer } from '../Components/HomeContainer'
import { BlueBtn } from '../../../layouts/BlueBtn';
import { LevelCard } from '../../../layouts/LevelCard';
import dashboardData from '../../../Data/dashboardData.json'
import { FiBarChart, FiTwitter } from 'react-icons/fi'
import { FaDiscord, FaInstagram } from 'react-icons/fa'
import { TbBrandTelegram } from 'react-icons/tb'
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import { Inputs } from '../../../layouts/Inputs';
import { BsTwitter, BsLinkedin, BsFacebook, BsTelegram, BsInstagram, BsDiscord } from 'react-icons/bs'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import fetch from 'node-fetch';

const faqData = [
    {
        question: 'What is Moon Republic?',
        answer: 'Moon Republic is a leading online platform that provides comprehensive courses and educational resources in the field of crypto and blockchain. Our platform is designed to help individuals gain knowledge, skills, and practical experience in this rapidly evolving industry.',
    },
    {
        question: 'Are the courses suitable for beginners?',
        answer: 'Yes, we have courses suitable for beginners as well as those with intermediate and advanced knowledge. Our course catalog is designed to cater to learners at different stages of their crypto and blockchain journey. Whether you are new to the industry or looking to deepen your understanding, you will find courses that suit your level of expertise',
    },
    {
        question: 'How do I access the courses?',
        answer: 'Once you enroll in a course on Moon Republic, you will gain immediate access to the course materials. Our platform allows you to study at your own pace, and you can access the courses anytime, anywhere using a computer or mobile device with an internet connection.',
    },
    {
        question: 'Do I receive a certificate upon course completion?',
        answer: 'Yes, upon successful completion of a course, you will receive a certificate from Moon Republic. Our certificates are recognized in the industry and can enhance your professional profile, demonstrating your proficiency in the subject matter.',
    },
    {
        question: 'How can I get started?',
        answer: 'To get started, simply sign up for our waitlist by providing your email address. By doing so, you will be among the first to gain access to our courses.',
    },

];

const heroData = [
    {
        id: 1,
        title: 'Flexible Learning',
        des: 'Study at your own pace, anytime, anywhere. Fit learning into your busy schedule.',
        images: './images/learningr.png',
    },
    {
        id: 2,
        title: 'Expert Instructions',
        des: 'Learn from industry experts as instructors, providing valuable insights and knowledge.',
        images: './images/instructionsr.png',
    },

    {
        id: 3,
        title: 'Comprehensive  Course Catalog',
        des: 'Embark on a comprehensive crypto and blockchain learning journey with our diverse course catalog that caters to learners of all levels.',
        images: './images/catalogr.png',
    },
    {
        id: 4,
        title: 'Practical Learning',
        des: 'Gain hands-on experience through interactive exercises, case studies, and real-world examples.',
        images: './images/practicalr.png',
    },

    {
        id: 5,
        title: 'Thriving Community',
        des: 'Engaging learning environment and a supportive community of fellow learners.',
        images: './images/communityr.png',
    },

    {
        id: 6,
        title: 'Earn rewards',
        des: 'you have the power to earn generous rewards by sharing our platform with others.',
        images: './images/rewardr.png',
    }
];


const heroDataL = [
    {
        id: 1,
        title: 'Blockchain Fundamentals',
        images: './images/blockchainimage.png',
    },
    {
        id: 2,
        title: 'Cryptocurrency Trading',
        images: './images/cryptocurrencyr.png',
    },

    {
        id: 3,
        title: 'Smart Contracts and Decentralized Applications',
        images: './images/smartcontractsr.png',
    },
    {
        id: 4,
        title: 'DeFi and Blockchain Governance',
        images: './images/defir.png',
    },

    {
        id: 5,
        title: 'NFTs and Digital Assets',
        images: './images/nftsimgr.png',
    },

    {
        id: 6,
        title: 'Crypto Investing Strategies',
        images: './images/investingr.png',
    }
];


const Waitlist = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [email, setEmail] = useState('');

    const [email1, setEmail1] = useState('');

    const [firstname, setFirstname] = useState('');

    const [lastname, setLastname] = useState('');

    const [message, setMessage] = useState('');

    const [buttonText, setButtonText] = useState("Join Waitlist");

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [isLoading, setIsloading] = useState(false);

    const [isLoading1, setIsloading1] = useState(false);

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const sendEmail = async () => {

        const emailValid = validateEmail(email1);

        if (email1 == "" || !emailValid) {

            alert("Please enter a valid email");

            return false;

        }

        if (firstname == "") {

            alert('Please enter your name');

            return false;

        }

        if (lastname == "") {

            alert('Please enter your name');

            return false;

        }

        if (message == "") {

            alert('Please enter your message');

            return false;

        }

        setIsloading1(true);

        const request = await fetch(`https://foamy-apricot-relation.glitch.me/sendEmail?email=${email1}&firstname=${firstname}&lastname=${lastname}&message=${message}&authToken=Moonrepublic@400123`);

        const response = await request.text();

        console.log(response)

        if (response == "sent") {

            alert("You message has been sent successfully!");

            setIsloading1(false);

        } else {

            alert("An error occurred, please contact site administrator!");

            setIsloading1(false);

        }

    }

    const joinWaitlist = async () => {

        const emailValid = validateEmail(email);

        if (email == "" || !emailValid) {

            alert("Please enter a valid email");

            return false;

        }

        setIsloading(true);

        const request = await fetch(`https://unaija.com/moonwaitlist.php?email=${email}`);

        const response = await request.text();

        if (response == "joined") {

            alert("You have successfully joined our waitlist!");

            setButtonText("Join Waitlist");

            setIsloading(false);

        } else {

            alert("An error occurred, please contact site administrator!");

            setButtonText("Join Waitlist");

            setIsloading(false);

        }

    }

    return (
        <div id='/' >

            <div className="bg-hero-bg bg-cover" >
                <Header />
                <div className=' mt-4'>
                    {/* <h1 className=' mx-auto text-[2rem] md:text-[3rem] md:leading-[3.5rem] px-6 md:px-0 text-center font-bold font-Grotesk tracking-wide'> Explore Crypto and Blockchain <br className='hidden md:flex'/> Education Without Limits </h1>
                    <p className=' mt-4 md:mt-0 text-base text-center px-10  text-graytext tracking-wide'> Master crypto and blockchain with expert-led courses for
                        learners <br className='hidden md:flex'/> of all levels, equipping you for success
                        in the fast-paced industry. </p>

                    <div className='flex space-x-4 justify-center mt-8 md:mt-4 pb-20 md:pb-0'>
                        <BlueBtn className='w-[6rem] text-sm hover:text-graytext duration-300 ease-in-out hover:bg-white hover:cursor-pointer'> Get Started </BlueBtn>

                        <Link to='/signin'>
                            <BlueBtn className='w-[6rem] bg-white  text-graytext text-sm hover:text-white duration-300 ease-in-out hover:bg-primaryblue hover:cursor-pointer'> Sign In </BlueBtn>

                        </Link>
                    </div> */}

                    <h1 className='mx-auto text-[2rem] md:text-[72px] md:leading-[3.5rem] px-6 md:px-0 text-center font-black md:tracking-wide uppercase pt-6 md:pt-0' style={{ lineHeight: "98.971%", fontFamily: "FONTSPRING DEMO - Integral CF Extra Bold", letterSpacing: "-1.8px" }}> MoonRepublic is  <br className='hidden sm:flex' /> launching soon </h1>
                    <p className='px-4 sm:px-0 mt-4 md:mt-2 text-base text-center sm:w-[60%] md:w-[40%] 2xl:w-[28%] mx-auto  text-graytext tracking-wide  '> Be among the first to embark on this educational journey and stay   updated on our upcoming launch. Sign up now to secure your spot!
                    </p>

                    <div className='pb-10 flex flex-col md:flex-row md:space-y-0 md:space-x-2 space-y-2 w-fit mx-auto mt-8  md:mt-4 '>

                        {/* <Inputs placeholder='Enter your email' className='w-[15rem] md:w-[18rem] shadow-xl shadow-blue-500/50'/>
                        <BlueBtn className='text-sm hover:text-graytext duration-300 shadow-lg shadow-blue-500/50 ease-in-out hover:bg-white hover:cursor-pointer w-[15rem] md:w-[10rem]'>  Join Our Waitlist </BlueBtn> */}

                        <input placeholder='Enter your email' className='w-[16rem] px-4 border border-[#5398FF] outline-none md:w-[18rem] shadow-lg shadow-blue-400 h-8 rounded-lg ' value={email} onChange={(e) => setEmail(e.target.value)} />

                        {isLoading &&
                            <button placeholder='Enter your email' className='w-[16rem] md:w-fit md:px-6 shadow-lg shadow-blue-400 h-8 rounded-lg bg-primaryblue text-white font-generalsans transition duration-300  ease-in-out hover:text-primaryblue hover:bg-white hover:cursor-pointer'> Joining.... </button>
                        }
                        {!isLoading &&
                            <button placeholder='Enter your email' className='w-[16rem] md:w-fit md:px-6 shadow-lg shadow-blue-400 h-8 rounded-lg bg-primaryblue text-white font-generalsans transition duration-300  ease-in-out hover:text-primaryblue hover:bg-white hover:cursor-pointer' onClick={() => { joinWaitlist() }}> Join Our Waitlist </button>
                        }
                    </div>

                    <div className='hidden md:flex'>
                        <img src='./images/heroImg.png' alt='hero-image'
                            className='w-[50rem] mx-auto mt-10' />
                    </div>
                </div>


            </div>
            <HomeContainer>



                <div className='text-center mt-10' id='about'>
                    <h1 className='font-bold md:pt-4  text-2xl md:text-[2rem] font-Grotesk'> Why Choose Us Moon Republic </h1>
                    <p className='text-sm text-graytext mt-2'> Freedom to balance learning and other commitments with a reward system </p>

                    <div className=' mt-8 md:mt-4 grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 w-fit mx-auto'>
                        {heroData.map(item => (
                            <div key={item.id} className='sm:h-[13rem] shadow mx-auto w-[95%] max-w-[17rem] md:w-[15rem] border border-gray bg-gradient-to-b from-[#FDFEFF] to-[#F7F9FE] hover:cursor-pointer hover:shadow rounded-lg px-4 pt-4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <h1 className='text-center md:text-start font-semibold text-sm text-[#153580] font-Grotesk tracking-wider'>{item.title}</h1>
                                    <p className='text-xs md:tracking-wide text-start sm:h-[4rem] hidden md:flex'>{item.des}</p>
                                </div>
                                <img src={item.images} className='h-[6rem] sm:h-[6.5rem] mt-1 md:mt-0 mx-auto' />
                            </div>


                        ))}
                    </div>
                </div>


            </HomeContainer>

            <div className='bg-primaryblue  text-white mt-14 pb-20'>
                <HomeContainer className='pt-16'>
                    <button className=' rounded-full text-[0.55rem] py-1.5 border px-2 w-fit mx-auto flex justify-center uppercase bg-gradient-to-b from-[#598AFF] to-[#306EFF] cursor-pointer'> Join our community </button>
                    <h1 className='text-center tracking-wider font-bold text-2xl md:text-5xl  mt-4 font-Grotesk'> Discover. Connect. Thrive. </h1>
                    <p className='text-center md:text-sm mt-2  tracking-wide text-sm  '> Join our vibrant crypto community to connect with like-minded learners,
                        <br className='hidden md:flex' />    access valuable insights, and expand your network in the industry. </p>

                    <div className='flex w-fit justify-center mx-auto space-x-2 mt-6'>
                        <a href="https://twitter.com/MoonRepublic_io" target="_blank">
                            <div className='flex flex-col items-center  rounded-xl space-y-1 py-2.5 w-[3.5rem] bg-gradient-to-b from-[#598AFF] to-[#306EFF] cursor-pointer hover:from-[#5D74FF] hover:to-primaryblue'>
                                <FiTwitter className='text-xs' />
                                <p className='text-[0.5rem] uppercase'> Twitter </p>
                            </div>
                        </a>
                        <a href="https://instagram.com/MoonRepublic.io" target="_blank">
                        <div className='flex flex-col items-center  rounded-xl space-y-1 py-2.5 w-[3.5rem] bg-gradient-to-b from-[#598AFF] to-[#306EFF] cursor-pointer hover:from-[#5D74FF] hover:to-primaryblue'>
                            <FaInstagram className='text-xs' />
                            <p className='text-[0.5rem] uppercase'> Instagram </p>
                        </div>
                        </a>
                        <div className='flex flex-col items-center  rounded-xl space-y-1 py-2.5 w-[3.5rem] bg-gradient-to-b from-[#598AFF] to-[#306EFF] cursor-pointer hover:from-[#5D74FF] hover:to-primaryblue'>
                            <TbBrandTelegram className='text-xs' />
                            <p className='text-[0.5rem] uppercase'>   Telegram </p>
                        </div>
                    </div>
                </HomeContainer>
            </div>

            <HomeContainer className='mb-10'>
                <div className='text-center mt-10'>
                    <h1 className='font-bold  text-2xl md:text-4xl font-Grotesk'> Choose Your Learning Path </h1>
                    <p className=' text-sm mt-1 md:text-base text-graytext'> A wealth of knowledge curated just for you </p>

                    <div className=' mt-8 md:mt-4 grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 w-fit mx-auto'>
                        {heroDataL.map(item => (
                            <div key={item.id} className='sm:text-center h-fit py-2  md:h-[13rem] hover:cursor-pointer hover:shadow-md bg-gradient-to-b from-white to-[#E4ECFF] mx-auto w-[90%]   max-w-[17rem] md:w-[15rem] border border-gray rounded-lg  px-4 pt-4'>
                                <img src={item.images} className='h-[5rem] md:h-[9rem] mx-auto ' />
                                <h1 className='font-semibold text-sm md:text-sm text-center md:text-start  md:w-[80%] h-[5rem] sm:h-[3rem]  font-Grotesk text-[#153580]'>  {item.title} </h1>
                            </div>

                        ))}
                    </div>
                </div>
            </HomeContainer>

            <div className="bg-[#2255CC]" id='faqs'>
                <HomeContainer className='pb-20'>
                    <div className="text-center pt-10 md:pt-20 text-white">
                        <h1 className="md:text-4xl tracking-wide text-lg font-semibold font-Grotesk">Frequently Asked Questions</h1>
                        <p className="text-sm text-textgray mt-2">Have any questions? We’re here to help</p>
                    </div>

                    <div className=' w-full md:w-[80%] lg:w-[50%] mx-auto text-white '>
                        {faqData.map((faq, index) => (
                            <div className="mt-4 border-b  border-textgray" key={index}>
                                <div
                                    className="flex items-center justify-between py-4 cursor-pointer transiton ease-out duration-500 "
                                    onClick={() => toggleAccordion(index)}
                                >
                                    <h2 className="text-base text-white   font-semibold">{faq.question}</h2>
                                    <span className="text-textgray">
                                        {activeIndex === index ? (
                                            <AiOutlineMinusCircle size={15} className='text-white font-bold' />
                                        ) : (
                                            <AiOutlinePlusCircle size={15} className='text-white font-bold' />
                                        )}
                                    </span>
                                </div>


                                <div
                                    className={`overflow-hidden transition-all duration-300 ${activeIndex === index ? 'max-h-full' : 'max-h-0'
                                        }`}
                                >
                                    <p className="text-textgray text-sm tracking-wide mb-4  transition-all duration-300">{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </HomeContainer>
            </div>

            <HomeContainer>
                <div className='pt-14' id='contact'>
                    <button className=' text-primaryblue font-semibold rounded-full text-xs py-1.5 border-l-2   px-4 w-fit mx-auto flex justify-center bg-gradient-to-b from-[#D2DFFF] to-[#F6F9FF] cursor-pointer'> Contact us </button>
                    <h1 className='font-bold mt-2 text-lg md:text-4xl text-center md:mt-3'> Get in touch </h1>
                    <p className='text-center md:mt-2 text-base  text-graytext'> We’d love to hear from you. Please fill out this form. </p>
                </div>


                <div className='md:w-[45%] mx-auto pt-10 pb-20'>
                    <div className='flex justify-between  md:flex-row space-x-4 mt-4'>
                        <div className='flex flex-col w-[45%]'>
                            <label className='text-sm'> First name </label>
                            <Inputs placeholder='First name' />
                        </div>

                        <div className='flex flex-col w-[45%]'>
                            <label className='text-sm'> Last name </label>
                            <Inputs placeholder='Last name' />
                        </div>
                    </div>
                    <div className='flex flex-col mt-4'>
                        <label className='text-sm'> Email </label>
                        <Inputs placeholder='Email' />
                    </div>

                    <div className='flex flex-col mt-4'>
                        <label className='text-sm'> Message </label>
                        <Inputs placeholder='' className='h-[6rem]' />
                    </div>

                    <BlueBtn className='mt-8 hover:text-graytext hover:border-gray hover:border duration-500 font-generalsans ease-in hover:bg-white hover:cursor-pointer'> Send Message </BlueBtn>
                </div>

                {/* 
            <div className='flex flex-col md:flex-row justify-center md:space-x-20 bg-[#EAECF0] px-4 md:px-20 py-8 mt-20 rounded-lg mb-20'>
                    <div className=' md:w-[40%] '>
                        <h1 className='font-bold text-base'> Join 2,000+ subscribers </h1>
                        <p className='text-sm tracking-wide w-[80%] mt-2 md:mt-0 text-graytext'> Stay in the loop with the latest news 
                            and insights in crypto and blockchain </p>
                    </div>

                    <div className='md:w-[45%]'>
                        <div className='flex flex-col md:flex-row md:space-x-2 '>
                                <div className='flex flex-col md:items-center mt-2 md:mt-0'>
                                <Inputs placeholder='Enter your email' className='h-[2rem]  text-xs w-full pt-1'/>
                                    <p className='text-sm tracking-wide mt-2 md:mt-1 text-start  text-graytext'> We care about your data in our privacy policy
                                 </p>
                                </div>
                            <BlueBtn className='w-fit px-4 h-[2rem] flex item-center pt-1 mt-3  md:mt-1   hover:text-graytext duration-300 ease-in hover:bg-white hover:cursor-pointer'> Subscribe </BlueBtn>
                        </div>
                    </div>
            </div> */}
            </HomeContainer>

            <div className='bg-primaryblue'>
                <HomeContainer>
                    <div className=' flex pt-10 flex-col md:flex-row justify-between border-b border-textgray pb-10'>
                        <div className='text-white'>
                            <div className='  flex flex-row   space-x-1   items-center w-fit mx-auto md:mx-0'>
                                <img src='./images/logob.png' alt='logo'
                                    className='h-8' />
                                <h1 className=' text-xs font-semibold  '> Moon <br /> Republic </h1>
                            </div>
                            <p className=' font-generalsans text-sm tracking-wider mt-4 text-center w-[70%] md:w-full mx-auto md:mx-0 text-textgray'> Explore Crypto and Blockchain Education Without Limits </p>

                            
                        </div>

                    </div>

                    <div>
                        <p className='text-center text-xs mt-4 text-textgray pb-8 font-generalsans'> © 2023 Moonrepublic. All rights reserved.</p>
                    </div>
                </HomeContainer>

            </div>


        </div>
    )
}

export default Waitlist;
