import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../layouts/Container'
import { Card } from '../../layouts/Card'
import { Inputs } from '../../layouts/Inputs';
import { BlueBtn } from '../../layouts/BlueBtn';
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';

const SignIn = () => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const processLogin = async () => {

    setIsLoading(true);

    if (!username || !password) {
      toast.error(`Enter your username and password to proceed`, {
        position: "top-center"
      })
      setIsLoading(false);
      return;
    }

    const body = { username: username, password: password };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/processLogin`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });

    const data = await response.json();

    console.log(data.feedback);

    if (data.feedback == "success") {
      toast.success(`${data.message}`, {
        position: "top-center"
      })

      const accessToken = data.accessToken;

      dispatch({ type: 'LOGIN', auth: { userId: data.userId, username: username, loggedIn: true, accessToken } });



      navigate('/dashboard');

    } else if (data.feedback == "error") {

      toast.error(`${data.message}`, {
        position: "top-center"
      })

    } else {
      toast.error(`An unknown error occurred, please contact admin`, {
        position: "top-center"
      })
    }

    setIsLoading(false);

  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse)
    },
    onError: error => {
      console.log('Login Failed' + error);
    }
  });

  return (
    <Container>
      <Card>
        <div className=''>

          <div className='text-center'>
            <img src='./images/logo.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl'> Welcome Back </h1>
            <p className='text-sm md:text-xs'>
              Oops, I don’t have an account.{' '}
              <Link to='/signup' className='underline font-semibold text-primaryblue'>
                Sign up
              </Link>
            </p>
          </div>

          <div>
            <form className='text-sm md:text-xs flex flex-col mt-6'>
              <label> Email/Username </label>
              <Inputs placeholder='Enter your email'
                type='email' value={username} onChange={(e) => {
                  setUsername(e.target.value)
                }} />

              <label className='mt-4'> Password </label>
              <Inputs placeholder='*************'
                type='password' value={password} onChange={(e) => {
                  setPassword(e.target.value)
                }} />
            </form>
          </div>

          <div>
            <BlueBtn className='mt-4 text-sm md:text-xs' onClick={() => {
              processLogin();
            }}>
              {isLoading &&
                <>Signing In...</>
              }
              {!isLoading &&
                <>Sign in</>
              }
            </BlueBtn>
            <BlueBtn className='mt-2 border text-sm md:text-xs border-gray bg-transparent text-black flex  justify-center items-center space-x-2' onClick={() => {
              loginWithGoogle();
            }}> <span className='mr-1'> <FcGoogle /> </span> Sign in with Google  </BlueBtn>
            <p className='text-sm md:text-[0.65rem] text-center mt-2 pb-10'> By signing in, you agree to our Terms of Use and Privacy Policy </p>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default SignIn
