import tw from "tailwind-styled-components"


export const Card = tw.div `
bg-white 
sm:w-[20rem]
md:w-[20rem]
rounded-md
w-[95%]
max-w-[23rem]
mx-auto
px-4
md:px-6
`