import React, { useState } from 'react';
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { AiFillPlayCircle, AiOutlineClockCircle, AiOutlineFileText, AiOutlineLeft } from 'react-icons/ai'
import { TbWorld } from 'react-icons/tb'
import courseData from '../../Data/coursesData.json'
import { RiArrowDownSLine } from 'react-icons/ri'
import { BsDot, BsCircle } from 'react-icons/bs'
import { BlueBtn } from '../../layouts/BlueBtn';
import { BiLockAlt } from 'react-icons/bi';
import Modal from '../../components/Modal';

const Intermediate_preview = () => {
    const [selectedItem, setSelectedItem] = useState(courseData[1].id);
    return (
        <DashboardContainer>
            <NavContainer className='hidden md:flex'>

                <div>
                    <h1 className='font-bold text-2xl ml-2 lg:ml-0'> Course </h1>
                </div>

                <Navbar />

            </NavContainer >

            <div className='flex flex-row space-x-1  md:space-x-2 mt-6'>
                    <BlueBtn className='w-fit px-1 md:px-3 bg-transparent text-black flex items-center'> Beginners <BiLockAlt className='ml-1' /> </BlueBtn>
                    <BlueBtn className='w-fit px-1 md:px-3 bg-transparent  border border-[#F79009] text-[#F79009] font-semibold '> Intermediate  </BlueBtn>
                    <BlueBtn className='w-fit px-1 md:px-3 bg-transparent text-black flex items-center '> Advanced <BiLockAlt className='ml-1' /> </BlueBtn>
                </div>

            <div className='md:px-4'>
                <div className='flex md:flex-row flex-col-reverse mt-4 '>

                    <div className=' w-full md:w-[30%]  md:mt-[1rem] px-4'>
                        <div className=' justify-between items-center hidden md:flex'>
                            <p className='flex items-center text-sm'>   Intermediate Outline </p>
                            <BiLockAlt />
                        </div>

                        <div>
                            {courseData.map(item => (
                                <div key={item.id}  >

                                    <div className='flex items-center space-x-1 mt-4 hover:cursor-pointer mb-4'>
                                        <div>
                                            <RiArrowDownSLine />
                                        </div>

                                        <div className='flex flex-row justify-between w-full items-center'>
                                            <div className='md:w-[85%]'>
                                                <h1 className=' font-semibold  w-[99%] max-w-[13rem]  truncate text-sm'> {item.title} </h1>
                                                <p className=' text-xs  flex items-center'> {item.lectures} <BsDot /> {item.duration} </p>
                                            </div>

                                            <BiLockAlt />
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </div>

                    <div className=' w-full md:w-[70%] 2xl:w-[60%] md:ml-4'>
                        <div>
                            <p className='text-sm'> Course preview </p>
                            <h1 className=' text-sm font-semibold'> Introduction and what we will cover </h1>
                        </div>
                        <div className='rounded-md mt-4'>
                            <img src='./images/preview.png' alt=''
                                className='rounded-md w-full max-h-[25rem]  hover:cursor-pointer hover:shadow-xl' />

                            <div className='w-fit mx-auto text-center flex flex-col
                         justify-center hover:cursor-pointer relative top-[-8rem] md:top-[-14rem]'>
                                <AiFillPlayCircle className='w-fit mx-auto text-4xl text-primaryblue' />
                                <p className='text-sm text-white'>Play Preview </p>
                            </div>
                        </div>

                        <div className=' -mt-10 p-4 rounded-md'>
                            <h1 className='font-semibold text-sm'> Upgrade Account </h1>
                            <p className='text-sm'> You need to upgrade your account to access courses in Intermediate. Proceed to upgrade? </p>
                            <div className='flex flex-row space-x-2 mt-4'>
                                <BlueBtn className='w-fit px-4 bg-transparent text-black py-1 text-xs'> Cancel </BlueBtn>
                                <Modal/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardContainer>


    )
}

export default Intermediate_preview
