import React, { useState, useEffect } from 'react'
import { Inputs } from '../../layouts/Inputs';
import { BlueBtn } from '../../layouts/BlueBtn';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { AiOutlineDesktop } from 'react-icons/ai'
import { RxDotFilled } from 'react-icons/rx'
import { useSelector, useDispatch } from 'react-redux';
import {toast} from 'react-toastify'
const Password = () => {

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const changePassword = async () => {

        if (!currentPassword || !newPassword || !confirmPassword) {
            toast.error("All fields are compulsory", {
                position: "top-center"
            })
            return;
        }

        if(newPassword.length<6 || confirmPassword.length<6){
            toast.error("Password must be at least 6 characters", {
                position: "top-center"
            })
            return;
        }

        if(newPassword !== confirmPassword){
            toast.error("Passwords do not match", {
                position: "top-center"
            })
            return;
        }

        setIsLoading(true);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/changePassword/`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                userId,
                currentPassword,
                newPassword,
                confirmPassword
            })
        })
        const response = await request.json();
        try {
            if (response.feedback == "success") {
                toast.success("Password changed successfully", {
                    position: "top-center"
                });
                setIsLoading(false);
            } else {
                toast.error(response.message, {
                    position: "top-center"
                });
                setIsLoading(false);
            }
        } catch (e) {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
        }

    }

    return (
        <div className='px-4 '>
            <div>
                {isLoading &&
                    <div>
                        <span className="loader"></span>
                    </div>
                }
                <div className='flex  flex-col  items-start  border-b border-gray pb-4 mt-8'>
                    <label className='w-full lg:w-[13rem] text-sm  '>  Password </label>
                    <p className='text-xs'> Please enter your current password to change your password. </p>
                </div>


                <div className='flex  flex-col lg:flex-row lg:space-x-4  lg:items-center items-center  border-b border-gray pb-4 mt-4'>
                    <label className='w-full lg:w-[13rem] text-sm'> Current  password </label>
                    <Inputs className='py-1 text-sm w-full lg:w-[25rem]' placeholder='••••••••' onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} />
                </div>

                <div className='flex  flex-col lg:flex-row lg:space-x-4  lg:items-center   border-b border-gray pb-4 mt-4'>
                    <label className='w-full lg:w-[13rem] text-sm'> New password </label>

                    <div className=''>
                        <Inputs className='py-1 text-sm w-full lg:w-[25rem]' placeholder='••••••••' onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
                        <p className='text-xs mt-1'> Your new password must be more than 8 characters.</p>
                    </div>
                </div>

                <div className='flex  flex-col lg:flex-row lg:space-x-4  lg:items-center items-center  border-b border-gray pb-4 mt-4'>
                    <label className='w-full lg:w-[13rem] text-sm'> Confirm new  password </label>
                    <Inputs className='py-1 text-sm w-full lg:w-[25rem]' placeholder='••••••••' onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                </div>

                <div className=' flex flex-row space-x-2 mt-4 w-fit lg:ml-auto'>
                    <BlueBtn className='w-fit text-xs px-2' onClick={changePassword}> Update Password </BlueBtn>
                </div>

                {/*
                <div className='flex justify-between items-center mt-8 border-b border-gray pb-4'>
                    <div>
                        <h1 className='text-sm font-semibold'> Where you’re logged in</h1>
                        <p className='text-xs'> We’ll alert you via dannycrypt@moonrepublic.com
                            if there is any unusual activity on your account. </p>
                    </div>

                    <div>
                        <BiDotsVerticalRounded className='text-lg hover:cursor-pointer' />
                    </div>
                </div>

                <div className='flex mt-2 space-x-2 border-b border-gray pb-4'>
                    <AiOutlineDesktop className='text-2xl' />

                    <div className=''>
                        <div>
                            <div className='flex lg:flex-row  flex-col lg:items-center lg:space-x-2 text-sm '>
                                <p className='font-s '>2018 Macbook Pro 15-inch </p>
                                <p className='bg-[#ECFDF3] mt-1 lg:mt-0 w-fit text-[#12B76A] text-xs font-semibold flex flex-row items-center rounded-full pr-1.5 '> <span> <RxDotFilled className='text-lg' /> </span> Active now</p>
                            </div>
                        </div>

                        <p className='text-xs text-graytext mt-1 lg:mt-0'> Melbourne, Australia • 22 Jan at 10:40am </p>
                    </div>
                </div>

                <div className='flex mt-2 space-x-2 '>
                    <AiOutlineDesktop className='text-2xl' />

                    <div className=''>
                        <div>
                            <div className='flex flex-row items-center space-x-2 text-sm '>
                                <p className=''>2018 Macbook Pro 15-inch </p>

                            </div>
                        </div>

                        <p className='text-xs text-graytext'> Melbourne, Australia • 22 Jan at 10:40am </p>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}

export default Password
