import React, { useState, useEffect } from 'react';
import copy from 'clipboard-copy';
import { DashboardContainer } from '../../layouts/DashboardContainer';
import { NavContainer } from '../../layouts/NavContainer';
import Navbar from '../../components/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { RiDiscordFill } from 'react-icons/ri'
import { useSelector } from 'react-redux';

const Earnings = () => {

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const [copiedText, setCopiedText] = useState('');
  const [referralId, setReferralId] = useState(null);
  const [referrerDetails, setReferrerDetails] = useState({ invited: null, totalReward: null, pendingReward: null, earnedReward: null })

  const handleCopyClick = () => {
    const textToCopy = `https://moonrepublic.io/go/${referralId}`;
    copy(textToCopy);
    toast.success('Copied!');
  };

  const getReferralId = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/referralId/${userId}`, {
        method: 'GET',
        headers,
      });
      const response = await res.json();

      if (response.feedback == "error") {
      } else {
        setReferralId(response.data);
      }
    } catch (error) {

    }
  }

  const getReferrerDetails = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/referrerDetails/${userId}`, {
        method: 'GET',
        headers,
      });
      const response = await res.json();
      console.log(response)
      if (response.feedback == "error") {
      } else {
        let data = response.data;
        let totalReferrals = 0;
        let totalReward = 0;
        let pendingReward = 0;
        if (data.length > 0) {
          for (let a = 0; a < data.length; a++) {
            //console.log(data[a].paymentStatus)
            totalReferrals += data[a].totalReferrals;
            if (data[a].paymentStatus == "paid") {
              totalReward = '$'+5 * data[a].totalReferrals;
            }
            if (data[a].paymentStatus == "pending") {
              pendingReward = '$'+5 * data[a].totalReferrals;
            }
          }
          //console.log("total referrals", totalReferrals)
          setReferrerDetails({ ...referrerDetails, invited: totalReferrals, totalReward, pendingReward, earnedReward: totalReward })
          console.log(referrerDetails)
        }
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getReferralId();
    getReferrerDetails();
  }, [])

  return (
    <DashboardContainer>
      <NavContainer>
        <div>
          <h1 className='font-bold text-2xl ml-2 hidden md:flex '> My Earnings </h1>
        </div>

        <Navbar />
      </NavContainer>

      <div className=''>
        <div className='lg:flex lg:space-x-4 '>
          <div className=' w-full bg-white sm:w-[80%] mx-auto lg:mx-0 lg:w-[30rem] h-fit  p-4 rounded-md mt-4 '>
            <h1 className='font-bold text-graytext'> Spread the word and earn rewards </h1>
            <p className='text-sm  mt-2 text-graytext'> When someone signs up using your unique
              referral link, you and the referred user
              will earn $5 worth of Moonrepublic token
              after the user successfully signs up</p>

            <div className='flex flex-col md:flex-row md:space-x-1 lg:items-center mt-4'>
              <div className='flex justify-between border border-gray lg:w-fit lg:min-w-[13rem] max-w-[20rem] py-1 rounded-md px-2 items-center w-[95%] overflow-hidden mt-4'>
                <p className='text-xs overflow-hidden '> {!referralId ? '...' : `https://moonrepublic.io/go/${referralId}`} </p>
                <button
                  className="bg-primaryblue text-white  text-xs py-1 ml-4  px-4 rounded-md"
                  onClick={handleCopyClick}
                >
                  Copy
                </button>

              </div>
              <ToastContainer />

              <div className='flex flex-row space-x-2'>
                <div className='w-9 h-8 border  border-gray items-center flex justify-center mt-4 rounded-md'>
                  <FaTwitter className='text-[#03A9F4] hover:cursor-pointer' />
                </div>

                <div className='w-9 h-8 border items-center border-gray flex justify-center mt-4 rounded-md'>
                  <FaFacebookF className='text-black hover:cursor-pointer' />
                </div>

                <div className='w-9 h-8 border items-center border-gray flex justify-center mt-4 rounded-md'>
                  <RiDiscordFill className='text-[#7289DA] hover:cursor-pointer' />
                </div>
              </div>
            </div>
          </div>

          <div className=' w-full bg-white sm:w-[80%] mx-auto lg:mx-0 lg:w-[30rem] h-fit  p-4 rounded-md mt-4'>
            <h1 className='font-bold text-graytext'> Referrals </h1>

            <div className='text-graytext text-sm mt-2 flex justify-between'>
              <p> Invited </p>
              <p> {referrerDetails.invited ? referrerDetails.invited : '...'}</p>
            </div>

            <div className='text-sm mt-1  text-graytext flex justify-between'>
              <p> Total Reward </p>
              <p> {referrerDetails.totalReward ? referrerDetails.totalReward : '...'} </p>
            </div>

            <div className='text-sm mt-1 text-graytext flex justify-between'>
              <p> Pending Reward </p>
              <p>{referrerDetails.pendingReward ? referrerDetails.pendingReward : '...'}</p>
            </div>

          </div>
        </div>

        <div className='lg:flex lg:space-x-4'>
          <div className=' w-full bg-white sm:w-[80%] mx-auto lg:mx-0 lg:w-[30rem] h-fit  p-4 rounded-md mt-4 '>
            <h1 className='font-bold mb-4 text-graytext'> How it works? </h1>

            <div className='flex flex-row  space-x-2'>
              <div className='flex flex-col space-y-4 lg:space-y-1 items-center'>
                <img src='./images/share.png' alt='icon' />
                <img src='./images/dash.png' alt='icon' />
                <img src='./images/friends.png' alt='icon' />
                <img src='./images/dash.png' alt='icon' />
                <img src='./images/rewards.png' alt='icon' />
              </div>

              <div className=' w-[90%] lg:pr-6 text-sm'>
                <div>
                  <h1 className='font-semibold text-graytext' > Share link</h1>
                  <p className='text-graytext'> Invite your friends to join Moonrepublic
                    using your unique referral link </p>
                </div>

                <div className='mt-4'>
                  <h1 className='font-semibold text-graytext'> Your friend joins Moonrepublic</h1>
                  <p className='text-graytext'> Your friend gets 10% off their first
                    subscription when they sign up
                    using your link </p>
                </div>

                <div className='mt-4'>
                  <h1 className='font-semibold text-graytext'> Earn rewards</h1>
                  <p className='text-graytext'> On each signup, you receive a reward of $5.
                    You can use this to pay for your
                    subscriptions or withdraw as cash</p>
                </div>
              </div>
            </div>

          </div>

          <div className=' w-full bg-white sm:w-[80%] mx-auto lg:mx-0 lg:w-[30rem] h-fit  p-4 rounded-md mt-3  lg:mt-[-2.5rem]'>
            <h1 className='font-bold text-graytext'> Manage your rewards </h1>

            <p className='text-sm text-graytext'> When someone signs up using your unique referral link,
              you and the referred user will earn
              $5 worth of Moonrepublic token
              after the user successfully signs up </p>

            <div className='text-graytext text-sm mt-4 flex justify-between'>
              <p> Invited </p>
              <p> {referrerDetails.invited ? referrerDetails.invited : '...'}</p>
            </div>

            <div className=' text-graytext text-sm mt-1 flex justify-between'>
              <p> Potential rewards </p>
              <p> {referrerDetails.pendingReward ? referrerDetails.pendingReward : '...'} </p>
            </div>

            <div className=' text-graytext text-sm mt-1 flex justify-between'>
              <p> Earned </p>
              <p> {referrerDetails.earnedReward ? referrerDetails.earnedReward : '...'}</p>
            </div>

          </div>
        </div>
      </div>
    </DashboardContainer>
  )
}

export default Earnings
