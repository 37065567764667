import { useState, useEffect } from 'react';
import Checkbox from '../../components/Checkbox';
import Checkbox_rounded from '../../components/Checkbox_rounded';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BlueBtn } from '../../layouts/BlueBtn';

const Email = () => {

    const dispatch = useDispatch();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const [isLoading, setIsLoading] = useState(true);
    const [emailPreferences, setEmailPreferences] = useState({ news: "", tips: "", research: "", reminders: "" });

    const [selectedOption, setSelectedOption] = useState('none');

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setEmailPreferences({ ...emailPreferences, reminders: "true" });
    };

    const [newsChecked, setNewsChecked] = useState(false);
    const [tipsChecked, setTipsChecked] = useState(false);
    const [researchChecked, setResearchChecked] = useState(false);

    const getUser = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
                method: 'GET',
                headers,
            });
            const response = await res.json();
            if (response.feedback == "error") {
                toast.error(response.message, {
                    position: "top-center"
                });
                setIsLoading(false);
            } else {
                const pref = JSON.parse(response.data[0].emailPreferences);
                setEmailPreferences(pref);
                setSelectedOption(pref.reminders);
                setNewsChecked(pref.news);
                setTipsChecked(pref.tips);
                setResearchChecked(pref.research);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            toast.error("An error occurred", {
                position: "top-center"
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUser();
    }, [])

    const handleNewsChange = () => {
        setNewsChecked(!newsChecked);
        setEmailPreferences({ ...emailPreferences, news: !newsChecked });
    };

    const handleTipsChange = () => {
        setTipsChecked(!tipsChecked);
        setEmailPreferences({ ...emailPreferences, tips: !tipsChecked });
    };

    const handleResearchChange = () => {
        setResearchChecked(!researchChecked);
        setEmailPreferences({ ...emailPreferences, research: !researchChecked });
    };

    const updateEmailPreferences = async () => {
        setIsLoading(true);
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/updateEmailPreferences`, {
                method: 'POST',
                body: JSON.stringify({
                    userId,
                    news: newsChecked,
                    tips: tipsChecked,
                    research: researchChecked,
                    reminders: selectedOption
                }),
                headers,
            });
            const response = await res.json();
            if (response.feedback == "success") {
                getUser();
                toast.success(response.message, {
                    position: "top-center"
                });
                setIsLoading(false);
            } else {
                toast.error(response.message, {
                    position: "top-center"
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            toast.error("An error occurred", {
                position: "top-center"
            });
            setIsLoading(false);
        }
    }

    return (
        <div className='px-4'>
            <div className='border-b border-gray pb-4 mt-8'>
                <div className='flex justify-between  lg:items-center'>
                    <div>
                        <h1> Email notifications </h1>
                        <p className='text-xs w-[90%] lg:w-full'> Get emails to find out what’s going on when you’re
                            not online. You can turn them off anytime. </p>
                    </div>

                    <div>
                        <BiDotsVerticalRounded className='text-lg hover:cursor-pointer' />
                    </div>
                </div>
            </div>
            {isLoading &&
                <div>
                    <span className="loader"></span>
                </div>
            }
            {!isLoading &&
                <>
                    <div className='mt-4 lg:mt-0 pt-2 flex  lg:flex-row flex-col lg:space-x-10 border-b border-gray pb-4'>
                        <div className='lg:w-[18rem]'>
                            <h1 className='font-semibold text-sm'> Notification from us</h1>
                            <p className='text-sm text-graytext'> Receive the latest news, updates
                                and industry tutorials from us. </p>
                        </div>

                        <div className='flex flex-col space-y-3  lg:w-[25rem] '>

                            <div className='flex flex-col mt-3 lg:mt-0'>
                                <div className='flex space-x-2 items-center'>
                                    <Checkbox checked={newsChecked} onChange={handleNewsChange} />
                                    <h1 className='font-semibold text-sm'> News and updates </h1>
                                </div>
                                <p className='text-sm ml-5 text-graytext'>News about product and feature updates.</p>
                            </div>

                            <div className='flex flex-col'>
                                <div className='flex space-x-2 items-center'>
                                    <Checkbox checked={tipsChecked} onChange={handleTipsChange} />
                                    <h1 className='font-semibold text-sm'> Tips and tutorials </h1>
                                </div>
                                <p className='text-sm ml-5 text-graytext'>Tips on getting more out of Untitled.</p>
                            </div>

                            <div className='flex flex-col'>
                                <div className='flex space-x-2 items-center'>
                                    <Checkbox checked={researchChecked} onChange={handleResearchChange} />
                                    <h1 className='font-semibold text-sm'> User research</h1>
                                </div>
                                <p className='text-sm ml-5 text-graytext'>Get involved in our beta testing program or participate in paid product user research.</p>
                            </div>

                        </div>
                    </div>

                    <div className='flex lg:flex-row flex-col lg:space-x-10  pb-4 mt-4'>
                        <div className='md:w-[18rem]'>
                            <h1 className='font-semibold text-sm'> Reminders </h1>
                            <p className='text-sm text-graytext'> These are notifications to remind
                                you of updates you might have missed. </p>
                        </div>

                        <div className='flex flex-col space-y-3 w-full  lg:w-[25rem] '>
                            <div className='flex space-x-2 items-center mt-4 lg:mt-0'>
                                <label>
                                    <input
                                        type='radio'
                                        name='notificationOption'
                                        value='none'
                                        checked={selectedOption === 'none'}
                                        onChange={() => handleOptionChange('none')}
                                    />
                                    <span className='text-sm font-semibold'> Do not notify me</span>
                                </label>
                            </div>

                            <div className='flex flex-col'>
                                <div className='flex space-x-2 items-center'>
                                    <label>
                                        <input
                                            type='radio'
                                            name='notificationOption'
                                            value='important'
                                            checked={selectedOption === 'important'}
                                            onChange={() => handleOptionChange('important')}
                                        />
                                        <span className='font-semibold text-sm'> Important reminders only</span>
                                    </label>
                                </div>
                                <p className='text-sm ml-5 text-graytext'>Only notify me if the reminder is tagged as important.</p>
                            </div>

                            <div className='flex flex-col'>
                                <div className='flex space-x-2 items-center'>
                                    <label>
                                        <input
                                            type='radio'
                                            name='notificationOption'
                                            value='all'
                                            checked={selectedOption === 'all'}
                                            onChange={() => handleOptionChange('all')}
                                        />
                                        <span className='font-semibold text-sm'> All reminders</span>
                                    </label>
                                </div>
                                <p className='text-sm ml-5 text-graytext'>Notify me for all reminders.</p>
                            </div>



                        </div>
                    </div>

                    <div className='flex flex-row space-x-2 mt-4 w-fit lg:w-[15rem] justify-center items-center'>
                        <BlueBtn className='text-xs px-2' onClick={updateEmailPreferences}>Update Email Preferences</BlueBtn>
                    </div>
                </>
            }

        </div>
    )
}

export default Email
