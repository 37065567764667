import React, { useEffect, useState } from 'react'
import { Inputs } from '../layouts/Inputs';
import { BsPersonCircle } from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BlueBtn } from '../layouts/BlueBtn';

const Mydetails = () => {

    const dispatch = useDispatch();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const [isLoading, setIsLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [userData, setUserData] = useState({ id: "", fullName: "", email: "", username: "", photo: null });
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);

        // Create a preview URL for the selected image
        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagePreview(previewURL);
        }
    };

    const handleUpload = () => {
        if (!selectedFile) {
            alert('Please select an image to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('upload_preset', 'your_preset_name');
        console.log(formData);
        fetch(`${process.env.REACT_APP_API_URL}/upload}`, {
            method: 'POST',
            body: formData,
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from Cloudinary, which may include the uploaded image URL.
                console.log('Uploaded image URL:', data.secure_url);
            })
            .catch((error) => {
                setErrorMessage(JSON.stringify(error))
                console.error('Error uploading image:', error);
            });
    };

    const updateProfile = async () => {

        if (!userData.fullName) {
            toast.error("Please enter your full name", {
                position: "top-center"
            })
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('upload_preset', 'your_preset_name');
        formData.append('fullName', userData.fullName);
        formData.append('userId', userId);

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                }
            });

            const response = await request.json();
            console.log(response)

            if (response.feedback == "success") {

                toast.success("Profile updated successfully", {
                    position: "top-center"
                });

                getUser();

            } else {
                toast.error(response.message, {
                    position: "top-center"
                });
            }
            setIsLoading(false);

        } catch (error) {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
            console.error('Error uploading image:', error);
        }

    };


    const getUser = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
                method: 'GET',
                headers,
            });
            const response = await res.json();
            
            if (response.feedback == "error") {
                toast.error(response.message, {
                    position: "top-center"
                });
                setIsLoading(false);
            } else {
                setUserData(response.data[0]);
                setIsLoading(false);
            }
        } catch (error) {
            toast.error("An error occurred", {
                position: "top-center"
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUser();
    }, [])

    const [file, setFile] = useState(null);
    const [textValue, setTextValue] = useState('');

    const handleTextChange = (e) => {
        // Update the 'textValue' state with the text input value
        setTextValue(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(file)
        // Create a new FormData object
        const formData = new FormData();

        // Append the file and text input data to the FormData object
        formData.append('file', file);
        formData.append('textValue', textValue);

        try {
            // Send a POST request to the server with the FormData
            const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                // Handle a successful response (e.g., show a success message)
                console.log('Upload successful!');
            } else {
                // Handle errors (e.g., display an error message)
                console.error('Upload failed.');
            }
        } catch (error) {
            // Handle network or other errors
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className='px-4'>
                {isLoading &&
                    <div>
                        <span className="loader"></span>
                    </div>
                }
                {!isLoading &&
                    <>
                        <div className='flex flex-col lg:flex-row lg:space-x-4  lg:items-center  border-b border-gray pb-4 mt-8'>
                            <label className='w-full lg:w-[13rem] text-sm'> Name </label>
                            <Inputs className='py-1 text-sm w-full lg:w-[25rem]' placeholder='Your Name' value={userData.fullName} onChange={(e) => setUserData({ ...userData, fullName: e.target.value })} />
                        </div>

                        <div className='flex  flex-col lg:flex-row lg:space-x-4  lg:items-center items-center  border-b border-gray pb-4 mt-4'>
                            <label className='w-full lg:w-[13rem] text-sm'>  Username </label>
                            <Inputs className='py-1 text-sm w-full lg:w-[25rem]' placeholder='Username' value={userData.username} readOnly />

                        </div>

                        <div className='flex flex-col lg:flex-row lg:space-x-4  lg:items-center items-center  border-b  border-gray pb-4 mt-4'>
                            <label className='w-full lg:w-[13rem] text-sm'>  Email address </label>
                            <Inputs className='py-1 text-sm w-full lg:w-[25rem]' placeholder='user@moonrepublic.io' value={userData.email} readOnly />

                        </div>

                        <div className='flex flex-col lg:flex-row lg:items-center lg:space-x-4  border-b  border-gray pb-4 mt-4 mt-4 w-full justify-start '>
                            <div className='flex flex-col'>
                                <label className='w-full lg:w-[13rem]'> Your Photo </label>
                                <p className='text-xs'> This will be displayed on your profile </p>
                            </div>
                            {/*
                    <div className="flex flex-col lg:flex-row space-y-2">
                        {!userData.photo &&
                            <BsPersonCircle className="h-10 w-10 rounded-full mt-2 lg:mt-5 mb-2 lg:mb-0" style={{ fontSize: 40, color: "blue" }} />
                        }
                        {userData.photo &&
                            <img
                                className="h-10 w-10 rounded-full mt-2 lg:mt-3 mb-2 lg:mb-0"
                                src={userData.photo}
                                alt="profile picture"
                            />
                        }
                        <label
                            htmlFor="file-input"
                            className="text-center text-xs border-gray border rounded-md py-2 w-full lg:w-[24rem] lg:ml-4 cursor-pointer"
                        >
                            {selectedImage ? (
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    className="mx-auto h-7 w-7 mt-2"
                                    style={{ width: '80px', height: '80px' }}
                                />
                            ) : (
                                <div
                                    className="drop-zone"
                                    onDragOver={preventDefault}
                                    onDrop={handleFileDrop}
                                >
                                    <img
                                        src="./images/upload.png"
                                        alt="upload icon"
                                        className="mx-auto h-7 w-7 mt-2"
                                    />
                                    <p>
                                        {' '}
                                        <span className="text-blue-600 hover:cursor-pointer">
                                            {' '}
                                            Click to upload{' '}
                                        </span>{' '}
                                        or drag and drop <br /> SVG, PNG, JPG or GIF (max. 80x80px)
                                    </p>
                                </div>
                            )}
                            <input
                                type="file"
                                id="file-input"
                                accept=".svg, .png, .jpg, .jpeg, .gif"
                                onChange={handleFileSelect}
                                style={{ display: 'none' }}
                            />
                        </label>
                    </div>
                            */}

                            {/* NEW FILE UPLOADER */}
                            <div className='flex flex-col lg:flex-row space-y-2'>
                                {!userData.photo &&
                                    <BsPersonCircle className="h-10 w-10 rounded-full mt-2 lg:mt-5 mb-2 lg:mb-0" style={{ fontSize: 40, color: "blue" }} />
                                }
                                {userData.photo &&
                                    <img
                                        className="h-10 w-10 rounded-full mt-2 lg:mt-8 mb-2 lg:mb-0"
                                        src={userData.photo}
                                        alt="profile picture"
                                    />
                                }
                                <div className=' text-center text-xs border-gray border rounded-md py-2  w-full lg:w-[24rem] lg:ml-4'>

                                    <label htmlFor='fileInput'>

                                        {imagePreview ? (
                                            <div className='mt-2'>
                                                <img
                                                    src={imagePreview}
                                                    alt='Selected Image Preview'
                                                    className='max-w-[200px] mx-auto'
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <img
                                                    src='./images/upload.png'
                                                    alt='upload icon'
                                                    className='mx-auto h-7 w-7 mt-2'
                                                />
                                                <p>
                                                    <span className='text-blue-600 hover:cursor-pointer'>
                                                        Click to upload
                                                    </span>{' '}
                                                    or drag and drop <br /> SVG, PNG, JPG, or GIF (max. 80x80px)
                                                </p>
                                            </>
                                        )}
                                        <input
                                            type='file'
                                            id='fileInput'
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* END NEW FILE UPLOADER */}

                        </div>

                        <div className='flex flex-row space-x-2 mt-4 w-fit lg:w-[15rem] justify-center items-center'>
                            <BlueBtn className='text-xs px-2' onClick={updateProfile}>Update Profile</BlueBtn>
                        </div>
                    </>
                }

            </div>
        </>
    )
}

export default Mydetails
