import React, { useState } from 'react'
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { AiOutlineLeft } from 'react-icons/ai'
import { Inputs } from '../../layouts/Inputs'
import Checkbox from '../../components/Checkbox'
import { BlueBtn } from '../../layouts/BlueBtn'


const CryptoPay = () => {

    const cryptoAssets = [
        {
            asset: "USDC",
            chains: [
                { id: 1, name: "ERC20", contractAddress: "0x17a1jk1344asdd" },
                { id: 56, name: "BEP20", contractAddress: "0x17a1jk1344asd231a" },
            ],
        },
        {
            asset: "USDT",
            chains: [
                { id: 1, name: "ERC20", contractAddress: "0x17a1jk1344asdd" },
                { id: 56, name: "BEP20", contractAddress: "0x17a1jk1344asd231a" },
            ],
        },
    ];

    const getChains = (asset) => {
        setChainDetails({ ...chainDetails, id: "" })
        for (let a = 0; a < cryptoAssets.length; a++) {
            if (cryptoAssets[a].asset == asset) {
                setAvailableChains(cryptoAssets[a].chains)
            }
        }

    }

    const [availableChains, setAvailableChains] = useState([]);
    const [selectedCryptoAsset, setSelectedCryptoAsset] = useState({ name: null, contractAddress: null })
    const [selectedChainId, setSelectedChainId] = useState("");
    const [chainDetails, setChainDetails] = useState({ id: "", contractAddress: "" });

    //open({ view: 'Connect' })
    const pay = () => {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "{{api-key}}");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "price_amount": 3999.5,
            "price_currency": "usd",
            "pay_currency": "btc",
            "ipn_callback_url": "https://nowpayments.io",
            "order_id": "RGDBP-21314",
            "order_description": "Apple Macbook Pro 2019 x 1"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.nowpayments.io/v1/payment", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
    return (
        <DashboardContainer>
            <NavContainer>
                <div>
                    <h1 className='font-bold text-2xl hidden lg:flex'> Checkout  </h1>
                </div>

                <Navbar />
            </NavContainer>


            <div className='flex space-x-4 items-center md:mt-8 hover:cursor-pointer'>
                <AiOutlineLeft />
                <h1 onClick={() => { window.history.back() }}> Back </h1>
            </div>

            <div id="mainDiv" className='bg-white rounded-md p-4 lg:p-0 lg:bg-transparent mt-4 lg:mt-0'>
                <div className="flex flex-col justify-center items-center"> {/* Centering container */}

                    <div id='main' className='w-full mt-4 border-b border-gray pb-4 flex flex-col justify-center items-center'>
                        <div className='items-center' style={{ textAlign: "center" }}>
                            <h1 className='font-semibold'>Pay with Crypto</h1>
                            <br />
                            <p className='text-sm'>Amount: $50</p>
                        </div>
                    </div>

                    <div id='main' className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                        <div className='w-full lg:w-[18rem]'>
                            <h1>Select Crypto asset/network</h1>
                        </div>

                        <div className='py-1 w-full lg:w-[13rem] flex flex-col mt-2 lg:mt-0'>
                            <label>Asset</label>
                            <select
                                id="select"
                                className="w-full px-2 py-2 border border-gray rounded-lg bg-white shadow-md focus:outline-none focus:ring focus:border-blue-300"
                                onChange={(event) => {
                                    setSelectedCryptoAsset({ ...selectedCryptoAsset, name: event.target.value })
                                    getChains(event.target.value)
                                }}
                            >
                                <option value="">Select crypto asset</option>
                                {cryptoAssets.map((row, index) => {
                                    return (
                                        <option key={index} value={row.asset}>{row.asset}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className='py-1 w-full lg:w-[6rem] flex flex-col mt-2 lg:mt-0'>
                            <label>Network</label>
                            <select
                                id="select"
                                className="w-full px-2 py-2 border border-gray rounded-lg bg-white shadow-md focus:outline-none focus:ring focus:border-blue-300"
                                value={chainDetails.id}
                                onChange={(e) => {
                                    setChainDetails({ ...chainDetails, id: e.target.value })
                                }}
                            >
                                {!selectedCryptoAsset.name &&
                                    <option value="">Select Crypto Asset</option>
                                }
                                {selectedCryptoAsset.name ? (
                                    <>
                                        <option value="">Select network</option>
                                        {availableChains.map((row, index) => {
                                            return (
                                                <option key={index} value={row.id}>{row.name}</option>
                                            )
                                        })}
                                    </>
                                ) :
                                    (<></>)
                                }
                            </select>
                        </div>
                    </div>

                    <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>

                        <div className='lg:w-[18rem] w-full'>
                            <h1>Email address</h1>
                            <p className='text-sm'>Invoices will be sent to this email address.</p>
                        </div>

                        <div>
                            <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='menamena@mail.com' />
                        </div>
                    </div>

                    <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                        <div className='lg:w-[18rem] w-full'>
                            <h1>Country</h1>
                        </div>

                        <div>
                            <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='Australia' />
                            <div className='flex space-x-2 mt-2'>
                                <Checkbox />
                                <p className='text-sm'>Billing address same as contact address</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex w-full lg:w-[21rem] justify-between mt-2'>
                        <BlueBtn className='w-[45%] lg:w-[10rem] py-1 bg-white border border-gray text-sm text-black' >Cancel</BlueBtn>
                        <BlueBtn className='w-[45%] lg:w-[10rem] py-1 text-sm' onClick={() => { }}>Proceed</BlueBtn>
                    </div>
                </div>
            </div>


        </DashboardContainer>


    )
}

export default CryptoPay
