// src/actions/userActions.js

export const registerUser = (email) => ({
    type: 'REGISTER_USER',
    payload: email,
  });
  
  export const setRegistered = (isRegistered) => ({
    type: 'SET_REGISTERED',
    payload: isRegistered,
  });
  