import React, { useEffect, useState } from 'react';
import { DashboardContainer } from '../../layouts/DashboardContainer'
import Navbar from '../../components/Navbar'
import { NavContainer } from '../../layouts/NavContainer';
import Mydetails from '../../components/Mydetails';
import Password from './Password';
import Email from './Email';
import { SettingsBtn } from '../../layouts/Settingsbtn'
import Plan from './Plan';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('My details');
  const openTab = (event, tabName) => {
    setActiveTab(tabName);
  };

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (query.get("success")) {
      setActiveTab('Plan');
    }

    if (query.get("canceled")) {
      setActiveTab('Plan');
    }
  }, [])

  return (
    <DashboardContainer>
      <NavContainer>
        <div>
          <h1 className='font-bold text-2xl ml-2 lg:ml-0 hidden md:flex'> Settings </h1>
        </div>

        <Navbar />
      </NavContainer >

      {/* tabs SettingsBtn div */}
      <div className='  bg-white mt-2 py-6 rounded-md shadow'>
        <div className='text-graytext bg-transparent md:bg-white border-b border-gray pb-4 py-0.5 sm:mt-0 px-3   flex justify-start space-x-4  overflow-x-auto  text-sm'>
          <SettingsBtn
            className={`tablinks    ${activeTab === 'My details' ? 'active underline text-[#2255CC]' : ''
              }`}
            onClick={(event) => openTab(event, 'My details')}
          >
            My details
          </SettingsBtn >

          <SettingsBtn
            className={`tablinks   ${activeTab === 'Password' ? 'active underline   text-[#2255CC]' : ''
              }`}
            onClick={(event) => openTab(event, 'Password')}
          >
            Password
          </SettingsBtn>

          <SettingsBtn
            className={`tablinks   ${activeTab === 'Plan' ? 'active  underline text-[#2255CC]' : ''
              }`}
            onClick={(event) => openTab(event, 'Plan')}
          >
            Plan
          </ SettingsBtn>


          <SettingsBtn
            className={`tablinks    ${activeTab === 'Email' ? 'active  underline text-[#2255CC]' : ''
              }`}
            onClick={(event) => openTab(event, 'Email')}
          >
            Email
          </ SettingsBtn>

          {/* <SettingsBtn
            className={`tablinks   ${activeTab === 'Notifications' ? 'active  underline text-[#2255CC]' : ''
              }`}
            onClick={(event) => openTab(event, 'Notifications')}
          >
            Notifications
          </ SettingsBtn> */}


        </div>


        {/* tab SettingsBtns contents */}
        {activeTab === 'My details' &&
          (
            <div id="My details"
              className={`tabcontent  ${activeTab === 'My details' ? 'block ' : 'hidden'
                }`}
            >
              <Mydetails />
            </div>
          )}
        {activeTab === 'Password' &&
          (
            <div id="Password"
              className={`tabcontent  ${activeTab === 'Password' ? 'block ' : 'hidden'
                }`}
            >
              <Password />
            </div>
          )}
        {activeTab === 'Email' &&
          (
            <div id="Email"
              className={`tabcontent  ${activeTab === 'Email' ? 'block ' : 'hidden'
                }`}
            >
              <Email />
            </div>
          )}

        {activeTab === 'Plan' &&
          (
            <div id="Plan"
              className={`tabcontent  ${activeTab === 'Plan' ? 'block ' : 'hidden'
                }`}
            >
              <Plan />
            </div>
          )}
      </div>


    </DashboardContainer>
  )
}

export default Settings
