// src/store.js

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';

const authenticationReducer = (state = {}, action) => {
  switch(action.type) {
    case 'LOGIN':
      return { ...state, userAuth: action.auth };
    case 'LOGOUT':
      return { ...state, userAuth: null };
    default:
      return state;
  }
};

const referrerReducer = (state={}, action)=>{
  switch(action.type){
    case 'CREATE':
      return { ...state, referrer: action.referrer};
      case 'REMOVE':
        return { ...state, referrer: action.referrer};
      default:
        return state;
  }

}

const rootReducer = combineReducers({
  user: userReducer,
  userAuth: authenticationReducer,
  referrer: referrerReducer
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

export { store, persistor };
