import React from 'react';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import { BlueBtn } from '../layouts/BlueBtn';

function QRCodeGenerator({ data }) {

  const textToCopy = data;
  const copyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    toast.success('Address copied to clipboard!', {
      position: "top-center"
    });
  };

  return (
    <div>
      <h2>{data}</h2>
      <center>
        <BlueBtn onClick={copyToClipboard} className='w-[45%] lg:w-[10rem] py-1 bg-white border border-gray text-sm text-black' >Copy Address</BlueBtn>
      </center>
      <br />
      <center>
        <QRCode value={data} />
      </center>
    </div>
  );
}

export default QRCodeGenerator;
