import React, { useState } from 'react';
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { AiFillPlayCircle, AiOutlineClockCircle, AiOutlineFileText, AiOutlineLeft } from 'react-icons/ai'
import { BsPeople } from 'react-icons/bs'
import { TbWorld } from 'react-icons/tb'
import courseData from '../../Data/coursesData.json'
import { RiArrowDownSLine } from 'react-icons/ri'
import { BsDot, BsCircle } from 'react-icons/bs'
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { FiChevronsRight } from 'react-icons/fi';
import { FaCheckSquare } from 'react-icons/fa'

const BeginnersCourse = () => {

    const query = new URLSearchParams(window.location.search);

    const courseId = query.get('courseId');
    const category = query.get('category');

    const [selectedItem, setSelectedItem] = useState(courseData[1].id);

    return (
        <DashboardContainer>
            <NavContainer className='hidden md:flex'>

                <div>
                    <h1 className='font-bold text-2xl ml-2 lg:ml-0'> Course </h1>
                </div>

                <Navbar />

            </NavContainer >

            <div className='md:px-4'>

                <div className='flex space-x-4 items-center mt-8 hover:cursor-pointer'>
                    <AiOutlineLeft />
                    <h1> Back </h1>
                </div>

                <div className='flex md:flex-row flex-col mt-4 '>
                    <div className=' w-full md:w-[70%]'>
                        <div className='rounded-md'>
                            <img src='./images/blockc.png' alt=''
                                className='rounded-md w-full max-h-[25rem] hover:cursor-pointer hover:shadow-xl' />

                            <div className='w-fit mx-auto text-center flex flex-col
                         justify-center hover:cursor-pointer relative top-[-8rem] md:top-[-14rem]'>
                                <AiFillPlayCircle className='w-fit mx-auto text-4xl text-primaryblue' />
                                <p className='text-sm text-white'> Start Learning </p>
                            </div>
                        </div>

                        <div>
                            <div>
                                <p className='bg-[#D1FADF] font-semibold py-0.5 text-[#027A48] w-fit px-2 text-sm rounded-full mt-[-2rem]'> Beginners </p>
                                <h1 className='font-bold mt-2'> Intro to NFT </h1>
                                <p className='text-sm'> Learn the key elements of Non-Fungible Tokens
                                    including how to buy, "mint" and sell
                                    NFTs for business, fun and profit! </p>

                                <div className='flex  space-x-4'>
                                    <p className='flex  items-center text-sm'> <span className='mr-2'> <BsPeople /> </span> Plays </p>
                                    <p className='flex  items-center text-sm'> <span className='mr-2'> <TbWorld /> </span> English </p>
                                    <p className='flex  items-center text-sm'> <span className='mr-2'> <AiOutlineClockCircle /> </span> 12mins </p>
                                </div>


                            </div>

                            <div className='text-sm mt-4'>
                                <h1 className='font-semibold'> Course description </h1>
                                <p className='mt-2'> Updated with valuable new video lessons! </p>
                                <p className='mt-2'>  Quickly get the hands-on, working knowledge you
                                    need and earn your certificate of
                                    completion in NFTs (Non-Fungible Tokens)
                                    from a globally recognized blockchain,
                                    Bitcoin and cryptocurrency expert by enrolling now! </p>
                                <p> </p>
                            </div>
                        </div>
                    </div>

                    <div className=' w-full md:w-[30%] ml-4'>
                        <div className=' justify-between items-center hidden md:flex'>
                            <p className='flex items-center '>  <FiChevronsRight className='mr-4' /> Beginners Outline </p>
                            <img src='./images/loading.png' alt=''
                                className='h-3' />
                        </div>

                        <div>
                            {courseData.slice(1).map(item => (
                                <div key={item.id} onClick={() => setSelectedItem(selectedItem === item.id ? null : item.id)}  >

                                    <div className='flex items-center space-x-1 mt-4 hover:cursor-pointer mb-4'>
                                        <div>
                                            <RiArrowDownSLine />
                                        </div>

                                        <div>
                                            <h1 className='truncate text-sm font-semibold w-[99%] md:w-full'> {item.title} </h1>
                                            <p className=' text-xs  flex items-center'> {item.lectures} <BsDot /> {item.duration} </p>
                                        </div>
                                    </div>

                                    {selectedItem === item.id && item.listcourse && (
                                        <div className='ml-6 flex flex-col space-y-2'>
                                            {item.listcourse.slice(0, 3).map((course, index) => (
                                                <div className='flex flex-row items-center space-x-2'>
                                                    <div className='mb-2 flex flex-row space-x-2 items-center'>

                                                        <FaCheckSquare className='h-3' />

                                                    </div>

                                                    <div>
                                                        <p className='text-[0.9rem] w-[10rem] truncate ' key={index}>{course}</p>
                                                        <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> 02:26 mins </p>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className='flex flex-row items-center space-x-2'>
                                                <div className='mb-2 flex flex-row space-x-2 items-center'>

                                                    <BsCircle className='h-3' />

                                                </div>

                                                <div>
                                                    <p className='text-[0.9rem] w-[10rem] truncate ' > Setting up & securing your wallet (Metamask, Phantom, Zerion) </p>
                                                    <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> 02:26 mins </p>
                                                </div>
                                            </div>

                                            <div className='flex flex-row items-center space-x-2'>
                                                <div className='mb-2 flex flex-row space-x-2 items-center'>

                                                    <BsCircle className='h-3' />

                                                </div>

                                                <div>
                                                    <p className='text-[0.9rem] w-[10rem] truncate ' > NFT scams, safety & security tips </p>
                                                    <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> 02:26 mins </p>
                                                </div>
                                            </div>

                                            <div className='flex flex-row items-center space-x-2'>
                                                <div className='mb-2 flex flex-row space-x-2 items-center'>

                                                    <BsCircle className='h-3' />

                                                </div>

                                                <div>
                                                    <p className='text-[0.9rem] w-[10rem] truncate ' > Types of NFT Projects (Derivative projects et all) </p>
                                                    <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> 02:26 mins </p>
                                                </div>
                                            </div>

                                            <div className='flex flex-row items-center space-x-2'>
                                                <div className='mb-2 flex flex-row space-x-2 items-center'>

                                                    <AiOutlineFileText className='h-3' />

                                                </div>

                                                <div>
                                                    <p className='text-[0.9rem] w-[10rem] truncate ' > Action steps or Quiz </p>
                                                    <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> 02:26 mins </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardContainer>


    )
}

export default BeginnersCourse
