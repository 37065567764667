import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../layouts/Container'
import { Card } from '../../layouts/Card'
import { Inputs } from '../../layouts/Inputs';
import { BlueBtn } from '../../layouts/BlueBtn';
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FinalizingSetup = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state.user.email);
  //const referrer = '';
  const referrer = useSelector((state) => {
    if (state.referrer.referrer) {
      return state.referrer.referrer.referralId
    } else {
      return '';
    }
  })
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function isValidPassword(password) {
    // Check for a minimum of 6 characters
    if (password.length < 6) {
      return false;
    }

    // Check for at least 1 uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    return true;
  }

  const completeSignup = async (e) => {

    e.preventDefault();

    if (isLoading) {
      return;
    }

    if (!password2 || !password1 || !username) {

      toast.error("All fields are compulsory", {
        position: "top-center"
      })

      return;

    }

    if (!isValidPassword(password1)) {
      toast.error("Invalid password!", {
        position: "top-center"
      })
      return;
    }

    if (password2 != password1) {

      toast.error("Passwords don't match", {
        position: "top-center"
      })
      return;

    }

    setIsLoading(true);

    const request = await fetch(`${process.env.REACT_APP_API_URL}/completeSignup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        username: username,
        password: password1,
        referrer: referrer,
        requestKey: process.env.REACT_APP_SERVER_KEY
      }),
    });

    const response = await request.json();

    console.log(response);

    setIsLoading(false);

    if (Object.keys(response).length > 0) {

      if (response.feedback == "success") {

        toast.success("Registration completed successfully", {
          position: "top-center"
        })

        navigate('/signin');
        return;

      } else if (response.feedback == "completed") {

        toast.success("Registration already completed", {
          position: "top-center"
        })
        dispatch({type:"REMOVE", referrer: null})
        navigate('/signin');
        return;

      } else if (response.feedback == "error") {

        toast.success(`${response.message}`, {
          position: "top-center"
        })
        
        navigate('/signup');
        return;

      }
      else {

        toast.error("An error occurred!", {
          position: "top-center"
        })

      }

    } else {

      toast.success("An error occurred", {
        position: "top-center"
      })

    }

  }

  return (
    <Container>
      <Card>
        <div className=''>

          <div className='text-center'>
            <img src='./images/logo.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl'> Finalizing Setup </h1>
            <p className='text-sm md:text-xs'>
              Complete your account setup by providing us with these information below
            </p>
          </div>

          <div>
            <form className='text-sm md:text-xs flex flex-col mt-6' onSubmit={completeSignup}>
              <label> Username </label>
              <Inputs placeholder='Username' value={username}
                type='text' onChange={(e) => {
                  setUsername(e.target.value);
                }} />

              <label className='mt-4'> Password </label>
              <p>(6 characters min & atleast 1 Uppercase) </p>
              <Inputs placeholder='*************' value={password1}
                type='password' onChange={(e) => {
                  setPassword1(e.target.value)
                }} />

              <label className='mt-4'> Confirm Password </label>
              <Inputs placeholder='*************' value={password2}
                type='password' onChange={(e) => {
                  setPassword2(e.target.value)
                }} />

              <label className='mt-4'> Referral code </label>
              <Inputs placeholder='*************' value={referrer} readOnly={true} />

              <BlueBtn type='submit' className='mt-4 mb-10 text-sm md:text-xs'>
                {isLoading &&
                  <>Processing...</>
                }
                {!isLoading &&
                  <>Continue</>
                }
              </BlueBtn>
            </form>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default FinalizingSetup

