import React, { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function Go() {
  const { referralId } = useParams();
  const referrer = useSelector((state)=>{return state.referrer})
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "CREATE", referrer: {referralId:referralId} });
    //dispatch({type:"REMOVE",referrer:null})
    
  }, [referralId]);

  useEffect(()=>{
    if(referrer.referrer){
        navigate("/signup")
    }
  },[referrer])

  

  return <></>;
}
