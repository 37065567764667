import tw from "tailwind-styled-components"


export const Text = tw.p`
mt-1 
hover:cursor-pointer 
transition  
hover:text-white 
duration-200 
hover:text-semibold 
w-[8rem]  
py-1 
ml-2 
flex 
items-center
`