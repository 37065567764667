import tw from "tailwind-styled-components"


export const Quizfooter = tw.div `
md:px-10 px-6  
xl:max-w-[1180px]  flex 
justify-between bottom-0 
inset-x-0 bg-[#F9FAFB]  w-full md:w-[40%] text-black mx-auto 
mb-4
mt-10
md:mt-4
md:fixed


`