import React from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { FiBarChart2, FiBook, FiFlag } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

const BottomNav = () => {
  const location = useLocation();

  return (
    <section className="md:hidden block font-semibold fixed bottom-0  z-50 shadow-lg text-gray-800 bg-white">
      <div className="flex justify-between py-4 px-6 text-xl">
        <Link
          to="/dashboard"
          className={`flex flex-col items-center w-fit ${
            location.pathname === '/dashboard' ? 'text-blue-500' : ''
          }`}
        >
          <FiBarChart2 />
          {location.pathname === '/dashboard' && (
            <span className="text-sm">Dashboard</span>
          )}
        </Link>
        <Link
          to="/beginner"
          className={`flex flex-col items-center w-fit ${
            location.pathname === '/beginner' ? 'text-blue-500' : ''
          }`}
        >
          <FiBook />
          {location.pathname === '/beginner' && (
            <span className="text-sm">Academy</span>
          )}
        </Link>
        <Link
          to="/leaderboard"
          className={`flex flex-col items-center w-fit ${
            location.pathname === '/leaderboard' ? 'text-blue-500' : ''
          }`}
        >
          <FiFlag />
          {location.pathname === '/leaderboard' && (
            <span className="text-sm">Leaderboard</span>
          )}
        </Link>
        <Link
          to="/welcome"
          className={`flex flex-col items-center w-fit ${
            location.pathname === '/welcome' ? 'text-blue-500 ' : ''
          }`}
        >
          <AiOutlineFileText />
          {location.pathname === '/welcome' && (
            <span className="text-sm">Quizzes</span>
          )}
        </Link>
      </div>
    </section>
  );
};

export default BottomNav;
