import React, { useState, useEffect, useRef } from 'react';
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { AiFillPlayCircle, AiOutlineClockCircle, AiOutlineFileText, AiOutlineLeft } from 'react-icons/ai'
import { BsPeople } from 'react-icons/bs'
import { TbWorld } from 'react-icons/tb'
import courseData from '../../Data/coursesData.json'
import { RiArrowDownSLine } from 'react-icons/ri'
import { BsDot, BsCircle } from 'react-icons/bs'
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { FiChevronsRight } from 'react-icons/fi';
import { FaCheckSquare } from 'react-icons/fa'
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import VimeoVideo from './VimeoVideoPlayer';
import { toast } from 'react-toastify';
import { BlueBtn } from '../../layouts/BlueBtn';
import Vimeo from '@vimeo/player';

const ViewCourse = () => {

    const urlQuery = new URLSearchParams(window.location.search);

    const from = urlQuery.get('from');

    const dispatch = useDispatch();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const query = new URLSearchParams(window.location.search);

    const courseId = query.get('id');
    const lessonId = query.get('lessonId');
    const category = query.get('category');

    const categories = ['beginner', 'intermediate', 'advanced'];

    if (!categories.includes(category) || category == '' || courseId == '') {
        window.location.href = "/dashboard";
    }

    const [selectedItem, setSelectedItem] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isStartingVideo, setIsStartingVideo] = useState(false);
    const [courses, setCourses] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [courseTitle, setCourseTitle] = useState('');
    const [courseDescription, setCourseDescription] = useState("");
    const [data, setData] = useState([]);
    const [playVideo, setPlayVideo] = useState(false);
    const [lesson, setLesson] = useState([]);
    const [lessonStatuses, setLessonStatuses] = useState([]);
    const [resetSelected, setResetSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const nextLesson = useRef({ id: null });
    const [currentVideoStatus, setCurrentVideoStatus] = useState("notStarted");
    const [videoFrame, setVideoFrame] = useState(null)
    const nextCourse = useRef({ id: null });

    const cats = {
        'beginner': "Beginners",
        'intermediate': "Intermediate",
        'advanced': "Advanced"
    }



    useEffect(() => {
        if (from == "quiz") {
            console.log(from)
            setCurrentVideoStatus("ended");
            setPlayVideo(false);
        }
    }, [from])

    const viewLesson = async () => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/viewLesson/${lessonId}?courseId=${courseId}`, {
            method: 'GET',
            headers,
        })

        const response = await request.json();

        if (response.message == "token_expired") {
            dispatch({ type: 'LOGOUT' });
            return;
        }
        if (response.feedback == "success") {
            setLesson(response.data[0])
            if (response.data.length > 1) {
                nextLesson.current = response.data[1];
            } else {
                getNextCourse();
            }
            getCourses()
        } else {
            toast.error("An error occurred", {
                position: "top-center"
            })
        }

        setIsLoading(false);

    }

    const getCourses = async () => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/coursess/${cats[category]}`, {
            method: 'GET',
            headers,
        })

        const response = await request.json();

        if (response.message == "token_expired") {
            dispatch({ type: 'LOGOUT' });
            return;
        }

        if (response.feedback == "success") {
            setData(response.data)
        }

        setIsLoading(false);

    }


    const getNextCourse = async () => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/nextCourse/${courseId}?category=${cats[category]}`, {
            method: 'GET',
            headers,
        })

        const response = await request.json();

        if (response.message == "token_expired") {
            dispatch({ type: 'LOGOUT' });
            return;
        }

        if (response.feedback == "success") {
            if (response.data.length > 1) {
                nextCourse.current = response.data[1];
            }
        }
        setIsLoading(false);

    }

    const completeLesson = async () => {
        console.log("Complete lesson")
        let courseStatus = 0;

        if (!nextLesson.current.id && nextCourse.current.id) {
            courseStatus = 1;
        }
        const request = await fetch(`${process.env.REACT_APP_API_URL}/completeLesson`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                userId: userId,
                lessonId: lessonId,
                courseId: courseId,
                courseStatus: courseStatus
            })
        })

        const response = await request.json();

    }

    const vimeoPlayerRef = useRef(null);

    useEffect(() => {
        viewLesson();
    }, [])

    useEffect(() => {
        const videoFrame = document.getElementById('vimeo-video');
        if (videoFrame) {
            const initVimeoPlayer = () => {
                // Initialize the Vimeo player when the iframe element exists in the DOM
                vimeoPlayerRef.current = new Vimeo(videoFrame);

                // Add event listeners for 'play' and 'ended' events
                vimeoPlayerRef.current.on('play', () => {
                    setCurrentVideoStatus("playing");
                    enrollCourse();
                });
                vimeoPlayerRef.current.on('ended', () => {
                    setCurrentVideoStatus("ended");
                    setPlayVideo(false);
                    completeLesson();
                });

                // Optionally, you can set up other event listeners or perform additional actions here

                // Check if there is a stored playback time in localStorage
                const storedPlaybackTime = localStorage.getItem('vimeoPlaybackTime');
                if (storedPlaybackTime) {
                    // Set the video's current time to the stored value
                    vimeoPlayerRef.current.setCurrentTime(parseFloat(storedPlaybackTime));
                }

            };

            initVimeoPlayer(); // Initialize the player 

        }

        // Clean up event listeners and player when the component unmounts
        return () => {
            if (vimeoPlayerRef.current) {
                // Remove event listeners
                vimeoPlayerRef.current.off('play', onVideoStart);
                vimeoPlayerRef.current.off('ended', onVideoEnd);

                // Destroy the player
                vimeoPlayerRef.current.destroy().then(() => {
                    console.log('Player destroyed');
                });
            }
        };

    }, [document.getElementById('vimeo-video')])

    // Event handler for video start
    const onVideoStart = () => {
        console.log('Video started playing');
        // Add your code to handle video start here
    };

    // Event handler for video end
    const onVideoEnd = () => {
        console.log('Video ended');
        // Add your code to handle video end here
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    function groupData(data, index) {
        const groupedData = data.reduce((grouped, item) => {
            const courseId = item.courseId;

            // Create a new group if it doesn't exist
            if (!grouped[courseId]) {
                grouped[courseId] = {
                    courseName: item.courseName, // Include the 'name' property
                    courseDescription: item.courseDescription,
                    lessons: [],
                };
            }

            // Push the item to the corresponding group
            grouped[courseId].lessons.push(item);

            return grouped;
        }, {});

        // Convert the grouped object back to an array with courseId and name
        const groupedArray = Object.entries(groupedData).map(([courseId, { courseName, courseDescription, lessons }]) => ({
            courseId: Number(courseId), // Convert the key back to a number
            courseName,
            courseDescription,
            lessons,
        }));

        // Now, groupedArray contains the data grouped by courseId with names

        setCourses(groupedArray);
        if (groupedArray[index].lessons[0].lessonTitle) {
            setLessons(groupedArray[index].lessons)
        }
        if (!groupedArray[index].lessons[0].lessonTitle) {
            setLessons([])
        }
        setCourseTitle(groupedArray[index].courseName)
        setCourseDescription(groupedArray[index].courseDescription)

        for (let a = 0; a < groupedArray.length; a++) {
            if (groupedArray[a].courseId == courseId && resetSelected == false) {
                setSelectedItem(a)
            }
        }
    }

    useEffect(() => {
        const fetchLessonStatuses = async () => {
            const statuses = await Promise.all(lessons.map(async (lesson) => {
                return await getLessonStatus(lesson.lessonId);
            }));
            setLessonStatuses(statuses);
        };

        fetchLessonStatuses();
    }, [lessons]);

    useEffect(() => {
        setLessons([])
        if (data.length > 0) {
            groupData(data, selectedItem)
        }
    }, [data, selectedItem])


    const vimeoVideoId = '860846930';
    //const vimeoVideoId = '524025857';
    const vimeoVideoSrc = `https://player.vimeo.com/video/${vimeoVideoId}`

    const handleVideoStart = () => {
        // Callback function to start the video
        enrollCourse();
    };

    const handleVideoEnd = () => {
        // Callback function to execute when the video ends
        alert('Video ended!');
        setPlayVideo(false)
    };

    const vimeoVideoRef = useRef(null);

    const checkSubscription = async () => {

        setIsStartingVideo(true)

        const request = await fetch(`${process.env.REACT_APP_API_URL}/checkSubscription?userId=${userId}&category=${cats[category]}`, {
            method: 'GET',
            headers
        })
        const response = await request.json();

        if (response.message == "success") {
            setIsStartingVideo(false)
            setPlayVideo(true)
            return
        } else {
            setIsStartingVideo(false)
            setIsOpen(true);
            return;
        }

        // Callback function to start the video
        if (vimeoVideoRef.current) {
            vimeoVideoRef.current.startVideo();
            setPlayVideo(true);
        }
    };

    const enrollCourse = async () => {
        console.log("Enrolling course")
        const request = await fetch(`${process.env.REACT_APP_API_URL}/courseEnrollment`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                userId: userId,
                courseId: courseId,
                lessonId: lessonId
            })
        })

        const response = await request.json();

        console.log(response)

    }

    const getLessonStatus = async (lessonId) => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/getLessonStatus?userId=${userId}&lessonId=${lessonId}`, {
            method: 'GET',
            headers,
        })

        const response = await request.json();

        if (response.feedback == "success") {
            return response.data
        }

        return null;

    }

    const setIndex = (index) => {

    }

    return (
        <DashboardContainer>
            <NavContainer className='hidden md:flex'>

                <div>
                    <h1 className='font-bold text-2xl ml-2 lg:ml-0'> Course </h1>
                </div>

                <Navbar />

            </NavContainer >

            <div className='md:px-4'>

                <div className='flex space-x-4 items-center mt-8 hover:cursor-pointer'>
                    <AiOutlineLeft />
                    <h1 onClick={() => window.history.back()}> Back </h1>
                </div>
                {isLoading &&
                    <div>
                        <span className="loader"></span>
                    </div>
                }
                {!isLoading &&
                    <div className='flex md:flex-row flex-col mt-4 '>

                        <div className=' w-full md:w-[70%]'>
                            <div className='rounded-md'>

                                <div className='h-[15rem] sm:h-[5rem] md:h-[25rem] lg:h-[25rem] xl:h-[25rem]' style={{ display: playVideo ? 'block' : 'none', borderRadius: 5, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', border: "1px solid #f7f7f7", marginBottom: 50 }} >
                                    {/*<VimeoVideo
                                        videoId={vimeoVideoId}
                                        onVideoStart={handleVideoStart}
                                        onVideoEnd={handleVideoEnd}
                                        ref={vimeoVideoRef}
                />*/}
                                    <iframe
                                        id="vimeo-video"
                                        src={`${vimeoVideoSrc}`}
                                        frameBorder="0"
                                        allowFullScreen
                                        style={{
                                            width: '100%',  // Set width to 100%
                                            height: '100%', // Set height to 100%
                                            margin: 0,      // Remove margins
                                            padding: 0,      // Remove padding
                                            borderRadius: 5
                                        }}
                                    ></iframe>
                                </div>

                                <div style={{ display: !playVideo ? 'block' : 'none' }}>
                                    <img src='./images/blockc.png' alt='' className='rounded-md w-full max-h-[25rem] hover:cursor-pointer hover:shadow-xl' />
                                    {currentVideoStatus == "ended" ? (
                                        <div className='w-fit mx-auto text-center flex flex-col justify-center hover:cursor-pointer relative top-[-8rem] md:top-[-14rem]' onClick={checkSubscription}>
                                            <AiFillPlayCircle className='w-fit mx-auto text-4xl text-primaryblue' />
                                            <br />
                                            <p className='text-sm text-white' >

                                                {nextLesson.current.id && 'Thanks for completing this lesson. Click next lesson to continue or take a quiz to test your knowledge'}

                                                {!nextLesson.current.id && nextCourse.current.id && 'Thanks for completing this course. Click next course to continue or take a quiz to test your knowledge'}

                                                {!nextLesson.current.id && !nextCourse.current.id && 'You are awesome! You have completed all the courses in this category'}

                                            </p>
                                            <br />
                                            {nextLesson.current.id &&
                                                <>
                                                    {lesson.quizId &&
                                                        <a href={`/quiz?quizId=${lesson.quizId}&data=${courseId}-${lessonId}-${category}&realm=lesson`}>
                                                            <BlueBtn className='w-fit text-xs px-2 mb-2'>Take Quiz</BlueBtn>
                                                        </a>
                                                    }
                                                    <a href={`/view-course?id=${courseId}&lessonId=${nextLesson.current.id}&category=${category}`}>
                                                        <BlueBtn className='w-fit text-xs px-2'>Next Lesson</BlueBtn>
                                                    </a>
                                                </>
                                            }
                                            {!nextLesson.current.id && nextCourse.current.id &&
                                                <>
                                                    {lesson.quizId &&
                                                        <a href={`/quiz?quizId=${lesson.quizId}&data=${courseId}-${lessonId}-${category}&realm=lesson`}>
                                                            <BlueBtn className='w-fit text-xs px-2 mb-2'>Take Quiz</BlueBtn>
                                                        </a>
                                                    }
                                                    <a href={`/view-course?id=${nextCourse.current.id}&lessonId=${nextLesson.current.id}&category=${category}`}>
                                                        <BlueBtn className='w-fit text-xs px-2'>Next Course</BlueBtn>
                                                    </a>
                                                </>
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-fit mx-auto text-center flex flex-col justify-center hover:cursor-pointer relative top-[-8rem] md:top-[-14rem]' onClick={checkSubscription}>
                                            <AiFillPlayCircle className='w-fit mx-auto text-4xl text-primaryblue' />
                                            <p className='text-sm text-white' > {isStartingVideo ? 'Starting...' : 'Start Learning'} </p>
                                        </div>
                                    )}
                                </div>

                            </div>

                            <div>
                                <div>
                                    <p className='bg-[#D1FADF] font-semibold py-0.5 text-[#027A48] w-fit px-2 text-sm rounded-full mt-[-2rem]'> Beginners </p>
                                    <h1 className='font-bold mt-2'> {lesson.title} </h1>
                                    <p> </p>
                                    <div className='text-sm' dangerouslySetInnerHTML={{ __html: lesson.description }} />
                                    <p></p>
                                    <div className='flex  space-x-4'>
                                        <p className='flex  items-center text-sm'> <span className='mr-2'> <BsPeople /></span> {lesson.plays} Plays </p>
                                        <p className='flex  items-center text-sm'> <span className='mr-2'> <TbWorld /> </span> English </p>
                                        <p className='flex  items-center text-sm'> <span className='mr-2'> <AiOutlineClockCircle /> </span> {lesson.length}mins </p>
                                    </div>


                                </div>

                                <div className='text-sm mt-4'>
                                    <h1 className='font-semibold'> Course description </h1>
                                    <p className='mt-2'>

                                    </p>
                                    <div dangerouslySetInnerHTML={{ __html: courseDescription }} />
                                    <p> </p>
                                </div>
                            </div>
                        </div>

                        <div className=' w-full md:w-[30%] ml-4'>
                            <div className=' justify-between items-center hidden md:flex'>
                                <p className='flex items-center '>  <FiChevronsRight className='mr-4' /> {cats[category]} Outline </p>
                                <img src='./images/loading.png' alt=''
                                    className='h-3' />
                            </div>

                            <div>
                                {courses.map((item, index) => {

                                    return (
                                        <div key={index} onClick={() => {
                                            setResetSelected(true)
                                            setSelectedItem(index)
                                        }}  >

                                            <div className='flex items-center space-x-1 mt-4 hover:cursor-pointer mb-4'>
                                                <div>
                                                    <RiArrowDownSLine />
                                                </div>

                                                <div>
                                                    <h1 className='truncate text-sm font-semibold w-[99%] md:w-full'> {item.courseName} </h1>
                                                    {item.lessons[0].lessonTitle &&
                                                        <p className=' text-xs  flex items-center'> {item.lessons.length} Lectures <BsDot /> {item.length} </p>
                                                    }
                                                </div>
                                            </div>

                                            {selectedItem === index && (
                                                <div className='ml-6 flex flex-col space-y-2'>
                                                    {lessons.map((lesson, index) => {
                                                        const status = lessonStatuses[index];
                                                        return (
                                                            <a key={index} href={`/view-course?id=${item.courseId}&lessonId=${lesson.lessonId}&category=beginner`}>
                                                                <div className='flex flex-row items-center space-x-2'>

                                                                    <div className='mb-2 flex flex-row space-x-2 items-center'>
                                                                        {status &&
                                                                            <FaCheckSquare className='h-3' />
                                                                        }
                                                                        {!status &&
                                                                            <BsCircle className='h-3' />
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        <p className='text-[0.9rem] w-[10rem] truncate ' key={index}>{lesson.lessonTitle}</p>
                                                                        <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> {lesson.lessonLength}mins </p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}



                                                    {/*<div className='flex flex-row items-center space-x-2'>
                                   <div className='mb-2 flex flex-row space-x-2 items-center'>

                                       <AiOutlineFileText className='h-3' />

                                   </div>
                                   
                                   <div>
                                       <p className='text-[0.9rem] w-[10rem] truncate ' > Action steps or Quiz </p>
                                       <p className='text-xs flex items-center'> <span>  <AiOutlinePlayCircle className='text-xs mr-2' /> </span> 02:26 mins </p>
                                   </div>
                              
                               </div> */}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                }
            </div>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    {/* Blurred Background */}
                    <div
                        className="fixed inset-0 backdrop-blur-lg bg-white/30"
                        onClick={toggleModal}
                    />

                    <div className="bg-white p-6 rounded shadow-lg relative w-[24rem]">
                        <div>
                            <img src='./images/modalicon.png' alt=''
                                className='w-10 h-10 mx-auto' />
                            <h1 className='mt-4 font-bold text-sm text-center'> Upgrade Account </h1>
                            <p className='text-center text-sm font-semibold'> You need to upgrade your account to access
                                courses in {cats[category]}. Proceed to upgrade? </p>
                        </div>
                        <div className='flex justify-center space-x-4 mt-4'>
                            <BlueBtn
                                className="w-fit px-8 bg-transparent text-black py-2 text-xs border-gray"
                                onClick={toggleModal}
                            >
                                Cancel
                            </BlueBtn>
                            <BlueBtn className="w-fit px-8 py-2 text-xs" >
                                <a href="/settings">
                                    Proceed
                                </a>
                            </BlueBtn>

                        </div>
                    </div>
                </div>
            )}
        </DashboardContainer>
    )
}

export default ViewCourse;
