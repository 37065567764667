import React, { useEffect, useState, useRef } from 'react'
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { AiOutlineLeft } from 'react-icons/ai'
import { Inputs } from '../../layouts/Inputs'
import Checkbox from '../../components/Checkbox'
import { BlueBtn } from '../../layouts/BlueBtn'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import JsBarcode from 'jsbarcode';
import QRCodeGenerator from '../../components/QrCode'
import CountdownTimer from '../../components/Countdown'

const Cryptocheckout = () => {

    const query = new URLSearchParams(window.location.search);

    const paymentId = query.get('id');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const [isLoading, setIsLoading] = useState(true);

    const cryptoAssets = [
        {
            asset: "USDC",
            chains: [
                { id: 1, name: "ERC20", contractAddress: "0x17a1jk1344asdd" },
                { id: 56, name: "BEP20", contractAddress: "0x17a1jk1344asd231a" },
            ],
        },
        {
            asset: "USDT",
            chains: [
                { id: 1, name: "ERC20", contractAddress: "0x17a1jk1344asdd" },
                { id: 56, name: "BEP20", contractAddress: "0x17a1jk1344asd231a" },
            ],
        },
    ];

    const getChains = (asset) => {
        setChainDetails({ ...chainDetails, id: "" })
        for (let a = 0; a < cryptoAssets.length; a++) {
            if (cryptoAssets[a].asset == asset) {
                setAvailableChains(cryptoAssets[a].chains)
            }
        }

    }

    const chainNames = {
        1: "ethereum",
        56: "bsc",
    };

    const [availableChains, setAvailableChains] = useState([]);
    const [selectedCryptoAsset, setSelectedCryptoAsset] = useState({ name: null, contractAddress: null })
    const [selectedChainId, setSelectedChainId] = useState("");
    const [chainDetails, setChainDetails] = useState({ id: "", contractAddress: "" });
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentStarted, setPaymentStarted] = useState(false);
    const [paymentData, setPaymentData] = useState({ amount: 0, currency: '' });
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentAddress, setPaymentAddress] = useState(null);
    const [targetTimestamp, setTargetTimestamp] = useState(null);

    let qrCodeData = '';

    const getPaymentData = async () => {
        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/getPaymentData/${paymentId}`, {
                method: 'GET',
                headers,
            })

            const response = await request.json();

            if (response.data.length > 0) {

                if (response.data[0].paymentStatus == "expired" && !query.get('restart')) {

                    setPaymentStatus('expired');
                    setPaymentData({ amount: response.data[0].priceAmount, currency: response.data[0].payCurrency })
                    if (response.data[0].payCurrency != "" && response.data[0].network != "") {
                        console.log(response.data)
                        setSelectedCryptoAsset({ ...selectedCryptoAsset, name: response.data[0].payCurrency })
                        setChainDetails({ ...chainDetails, id: response.data[0].network })
                    }
                    setIsLoading(false)
                    return;

                } else {

                    setPaymentData({ amount: response.data[0].priceAmount, currency: response.data[0].payCurrency })

                    setPaymentAddress(response.data[0].payAddress)
                    if (query.get('paymentStarted')) {
                        setIsProcessing(true)
                        setPaymentStarted(true)
                        setSelectedChainId(response.data[0].network)
                        checkPaymentStatus(paymentId);
                        setTargetTimestamp(response.data[0].paymentTimeframe)
                    }

                    setIsLoading(false)
                }

            } else {
                navigate('/settings')
            }
        } catch (e) {

        }
    }

    const startPayment = async () => {
        console.log(selectedCryptoAsset, chainDetails)
        if (!selectedCryptoAsset.name) {
            toast.error("Please select asset", {
                position: "top-center"
            })
            return;
        }

        if (!chainDetails.id) {
            toast.error("Please select payment network", {
                position: "top-center"
            })
            return;
        }

        setIsProcessing(true)

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/startCryptoPayment`, {
                method: 'POST',
                body: JSON.stringify({
                    paymentId,
                    asset: selectedCryptoAsset.name,
                    chainId: chainDetails.id,
                }),
                headers,
            })

            const response = await request.json();

            if (response.feedback == "success") {
                window.location.href = "cryptocheckout?id=" + paymentId + "&paymentStarted=1";
            }
            setIsProcessing(false)

        } catch (e) {

        }
    }

    useEffect(() => {
        getPaymentData();
        return () => {
            clearTimeout(checkPaymentStatus);
        };
    }, [])

    useEffect(() => {
        //console.log(selectedCryptoAsset)
        //console.log(chainDetails)
    }, [selectedCryptoAsset, chainDetails])

    const checkPaymentStatus = async (invoiceId, retries=0) => {
        if(retries > 10) {
          return;
        }
        if (!paymentStatus) {
            // Your code here
            console.log('Recursive function is running...');

            // Perform the API fetch request
            try {
                const request = await fetch(`${process.env.REACT_APP_API_URL}/checkCryptoPayment/${invoiceId}`, {
                    method: "GET",
                    headers,
                });

                const response = await request.json();
                console.log(response);

                if (response.feedback === "success") {
                    if (response.data.length > 0) {
                        console.log(response.data[0].paymentStatus);
                        if (response.data[0].paymentStatus === "expired") {
                            setPaymentStatus('expired');
                            setPaymentStarted(false);
                            setIsProcessing(false);
                            return;
                        } else if (response.data[0].paymentStatus === "paid") {
                            window.location.href=`/settings?action=payment&invoiceId=${invoiceId}&success=true`;
                            return;
                        }
                    }
                }
            } catch (error) {
                console.error('API fetch error:', error);
            }

            setTimeout(() => checkPaymentStatus(invoiceId, retries+1), 60000);
        }
    };


    const refetch = async () => {
        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/getPaymentData/${paymentId}`, {
                method: 'GET',
                headers,
            })

            const response = await request.json();

            if (response.data.length > 0) {

                setPaymentData({ amount: response.data[0].priceAmount, currency: response.data[0].payCurrency })
                if (response.data[0].payCurrency != "" && response.data[0].network != "") {
                    console.log(response.data)
                    setSelectedCryptoAsset({ ...selectedCryptoAsset, name: response.data[0].payCurrency })
                    setChainDetails({ ...chainDetails, id: response.data[0].network })
                }

            }
        } catch (e) {

        }
    }

    const handleCountdownEnd = () => {
        setPaymentStatus('expired');
        setPaymentStarted(false)
        setIsProcessing(false)
    };

    const textToCopy = paymentAddress;
    const copyToClipboard = () => {
        const textArea = document.createElement('textarea');
        textArea.value = paymentData.amount;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        toast.success('Amount copied to clipboard!', {
            position: "top-center"
        });
    };

    return (
        <DashboardContainer>
            <NavContainer>
                <div>
                    <h1 className='font-bold text-2xl hidden lg:flex'> Checkout  </h1>
                </div>

                <Navbar />
            </NavContainer>


            <div className='flex space-x-4 items-center md:mt-8 hover:cursor-pointer'>
                <AiOutlineLeft />
                <h1 onClick={() => { window.history.back() }}> Back </h1>
            </div>

            <div id="mainDiv" className='bg-white rounded-md p-4 lg:p-0 lg:bg-transparent mt-4 lg:mt-0'>
                <div className="flex flex-col justify-center items-center"> {/* Centering container */}

                    {isLoading &&
                        <>
                            Loading....

                            <span className="loader"></span>

                        </>
                    }

                    {isProcessing &&
                        <>
                            Waiting for payment....
                            <span className="loader"></span>
                        </>
                    }

                    {!isLoading && !paymentStarted && paymentStatus != "expired" &&
                        <>
                            <div id='main' className='w-full mt-4 border-b border-gray pb-4 flex flex-col justify-center items-center'>
                                <div className='items-center' style={{ textAlign: "center" }}>
                                    <h1 className='font-semibold'>Pay with Crypto</h1>
                                    <br />
                                    <p className='text-sm'>Amount: ${paymentData.amount}</p>
                                </div>
                            </div>

                            <div id='main' className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                                <div className='w-full lg:w-[18rem]'>
                                    <h1>Select Crypto asset/network</h1>
                                </div>

                                <div className='py-1 w-full lg:w-[13rem] flex flex-col mt-2 lg:mt-0'>
                                    <label>Asset</label>
                                    <select
                                        id="select"
                                        className="w-full px-2 py-2 border border-gray rounded-lg bg-white shadow-md focus:outline-none focus:ring focus:border-blue-300"
                                        onChange={(event) => {
                                            setSelectedCryptoAsset({ ...selectedCryptoAsset, name: event.target.value })
                                            getChains(event.target.value)
                                        }}
                                    >
                                        <option value="">Select crypto asset</option>
                                        {cryptoAssets.map((row, index) => {
                                            return (
                                                <option key={index} value={row.asset}>{row.asset}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className='py-1 w-full lg:w-[6rem] flex flex-col mt-2 lg:mt-0'>
                                    <label>Network</label>
                                    <select
                                        id="select"
                                        className="w-full px-2 py-2 border border-gray rounded-lg bg-white shadow-md focus:outline-none focus:ring focus:border-blue-300"
                                        value={chainDetails.id}
                                        onChange={(e) => {
                                            setChainDetails({ ...chainDetails, id: e.target.value })
                                        }}
                                    >
                                        {!selectedCryptoAsset.name &&
                                            <option value="">Select Crypto Asset</option>
                                        }
                                        {selectedCryptoAsset.name ? (
                                            <>
                                                <option value="">Select network</option>
                                                {availableChains.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.id}>{row.name}</option>
                                                    )
                                                })}
                                            </>
                                        ) :
                                            (<></>)
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>

                                <div className='lg:w-[18rem] w-full'>
                                    <h1>Email address</h1>
                                    <p className='text-sm'>Invoices will be sent to this email address.</p>
                                </div>

                                <div>
                                    <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='' />
                                </div>
                            </div>

                            <div className='flex w-full lg:w-[21rem] justify-between mt-2'>
                                <BlueBtn className='w-[45%] lg:w-[10rem] py-1 bg-white border border-gray text-sm text-black' >Cancel</BlueBtn>
                                <BlueBtn className='w-[45%] lg:w-[10rem] py-1 text-sm' onClick={() => { startPayment() }}>Proceed</BlueBtn>
                            </div>
                        </>
                    }

                    {paymentStatus == "expired" &&
                        <>
                            <div id='main' className='w-full mt-4 border-b border-gray pb-4 flex flex-col justify-center items-center'>
                                <div className='items-center' style={{ textAlign: "center" }}>
                                    <h1 className='font-semibold'>Pay with Crypto</h1>
                                    <br />
                                    <p className='text-sm'>Amount: ${paymentData.amount}</p>
                                </div>
                            </div>

                            <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                                <div>
                                    <p className='text-sm'>Payment time has exceeded, please try again later</p>

                                </div>
                            </div>

                            <div className='flex w-full lg:w-[21rem] justify-between mt-2'>
                                <a href={`/cryptocheckout?id=${paymentId}&restart=1`}>
                                    <BlueBtn className='w-[45%] lg:w-[10rem] py-1 text-sm'>Try again</BlueBtn>
                                </a>
                                <BlueBtn className='w-[45%] lg:w-[10rem] py-1 bg-white border border-gray text-sm text-black' >Cancel</BlueBtn>

                            </div>
                        </>
                    }

                    {paymentStarted && !isLoading &&
                        <>
                            <div id='main' className='w-full mt-4 border-b border-gray pb-4 flex flex-col justify-center items-center'>
                                <div className='items-center' style={{ textAlign: "center" }}>
                                    <p className='text-sm'>Amount: {paymentData.amount} {paymentData.currency}</p>
                                    <BlueBtn onClick={copyToClipboard} className='w-[45%] lg:w-[10rem] py-1 bg-white border border-gray text-sm text-black' >Copy Amount</BlueBtn>
                                </div>
                            </div>
                            {paymentAddress &&
                                <>
                                    <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                                        <QRCodeGenerator data={paymentAddress} />
                                    </div>
                                    <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                                        <CountdownTimer targetTimestamp={targetTimestamp} onCountdownEnd={handleCountdownEnd} />
                                    </div>
                                </>
                            }

                            <div className='w-full flex flex-col justify-center items-center lg:flex-row flex-col lg:space-x-6 mt-2 border-b border-gray pb-4'>
                                <div>
                                    <p className='text-sm'>Send exactly {paymentData.amount} {paymentData.currency} to this address on {chainNames[selectedChainId]} network</p>
                                </div>
                            </div>

                        </>
                    }

                </div>
            </div>


        </DashboardContainer>


    )
}

export default Cryptocheckout
