import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar';
import { DashboardContainer } from '../../layouts/DashboardContainer';
import { NavContainer } from '../../layouts/NavContainer';
import { useSelector, useDispatch } from 'react-redux';
import { LevelCard } from '../../layouts/LevelCard';
import { FiBarChart } from 'react-icons/fi'

const SearchResult = () => {

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const query = new URLSearchParams(window.location.search);

  const keyword = query.get('keyword');
 
  if(!keyword || keyword==""){
    window.location.href="/dashboard";
   
  }

  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const searchCourses = async () => {

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/searchCourses`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          keyword
        })
      });

      const response = await request.json();
      console.log(response)
      if (response.feedback == "success") {
        setCourses(response.data)
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }

  }

  const categories = {
    Beginners: {
      value: 'beginner'
    },
    Intermediate: {
      value: 'intermediate'
    },
    Advanced: {
      value: 'advanced'
    }
  };

  useEffect(() => {
    searchCourses();
  }, [])

  return (
    <DashboardContainer>
      <NavContainer>
        <div>
          <h1 className='font-bold text-2xl ml-2 lg:ml-0 hidden md:flex'> Search </h1>
        </div>

        <Navbar />
      </NavContainer >
      {isLoading &&
        <div>
          <span className="loader"></span>
        </div>
      }
      {!isLoading &&
        <div className='mt-6 md:bg-white   rounded-md '>
          <h1 className='p-4'> Search result for {keyword}</h1>

          <div className='flex flex-row    lg:justify-between md:gap-5  gap-3 px-4 pb-4 overflow-x-auto'>
            {courses.length < 1 &&
              <>
                <h5 className='text-graytext'>No search result for {keyword}</h5>
                <a href="/beginner">
                  <button className="bg-primaryblue rounded-md text-white py-2 w-fit text-xs px-2"> View all courses </button>
                </a>
              </>
            }
            {courses.map((item, index) => (
              <a key={index} href={`/view-course?id=${item.courseId}&lessonId=${item.lessonId}&category=${categories[item.categoryName].value}`}>
                <LevelCard key={index} className=''>
                  <img src={'/images/nfts.png'}
                    alt='blockchain-image'
                    className='rounded-t-xl  h-[8rem] w-full ' />

                  <div className='px-4'>
                    <p className='mt-4 font-bold  text-sm'> {item.courseTitle} </p>
                    <div className='flex justify-between items-center mt-3 '>
                      {/* Conditionally set the background and text color based on item.level */}
                      <p
                        className={`px-1 py-1 text-xs rounded-full font-semibold flex  items-center  ${item.categoryName === 'Beginners'
                          ? ' text-[#027A48]'
                          : item.categoryName === 'Intermediate'
                            ? ' text-[#F79009]'
                            : item.categoryName === 'Advanced'
                              ? ' text-[#F04438]'
                              : ''
                          }`}
                      >
                        <FiBarChart className='mr-1' /> {item.categoryName}
                      </p>
                      <p className='flex items-center text-xs'> <span> <img src='./images/loading.png' alt='icon-loading' /> </span> {item.length}mins </p>
                    </div>
                  </div>
                </LevelCard>
              </a>
            ))}
          </div>
        </div>
      }
    </DashboardContainer>
  )
}

export default SearchResult
