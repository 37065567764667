import React, { useState, useEffect } from 'react';
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import { BlueBtn } from '../../layouts/BlueBtn';
import { BiLockAlt } from 'react-icons/bi'
import courseData from '../../Data/coursesData.json'
import { RiArrowDownSLine } from 'react-icons/ri'
import { BsDot } from 'react-icons/bs'
import { AiOutlinePlayCircle } from 'react-icons/ai';
import dashboardData from '../../Data/dashboardData.json'
import { Link } from 'react-router-dom';
import { LevelCard } from '../../layouts/LevelCard';
import { FiBarChart } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux';

const Advanced = () => {

    const dispatch = useDispatch();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const [selectedItem, setSelectedItem] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [courseTitle, setCourseTitle] = useState('');
    const [data, setData] = useState([]);

    const getBeginnersCourses = async () => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/coursess/Beginners`, {
            method: 'GET',
            headers,
        })

        const response = await request.json();
        //console.log(response)
        if (response.message == "token_expired") {
            dispatch({ type: 'LOGOUT' });
            return;
        }
        //console.log(response.data)
        if (response.feedback == "success") {
            setData(response.data)
        }

        setIsLoading(false);

    }

    useEffect(() => {
        getBeginnersCourses();
    }, [])

    function groupData(data, index) {
        const groupedData = data.reduce((grouped, item) => {
            const courseId = item.courseId;

            // Create a new group if it doesn't exist
            if (!grouped[courseId]) {
                grouped[courseId] = {
                    courseName: item.courseName, // Include the 'name' property
                    lessons: [],
                };
            }

            // Push the item to the corresponding group
            grouped[courseId].lessons.push(item);

            return grouped;
        }, {});

        // Convert the grouped object back to an array with courseId and name
        const groupedArray = Object.entries(groupedData).map(([courseId, { courseName, lessons }]) => ({
            courseId: Number(courseId), // Convert the key back to a number
            courseName,
            lessons,
        }));

        // Now, groupedArray contains the data grouped by courseId with names
        //console.log(groupedArray);
        setCourses(groupedArray);
        console.log()
        if (groupedArray[index].lessons[0].lessonTitle) {
            setLessons(groupedArray[index].lessons)
        }
        if (!groupedArray[index].lessons[0].lessonTitle) {
            setLessons([])
        }
        setCourseTitle(groupedArray[index].courseName)
    }

    useEffect(() => {
        if (data.length > 0) {
            groupData(data, selectedItem)
        }
    }, [data, selectedItem])

    return (
        <DashboardContainer>
            <NavContainer>

                <div>
                    <h1 className='font-bold text-2xl ml-2 lg:ml-0 hidden md:flex'> Academy </h1>
                </div>

                <Navbar />

            </NavContainer >

            <div className='flex mt-4 justify-between'>
                <div className='flex flex-row space-x-1  md:space-x-2 w-fit mx-auto md:mx-0'>
                    <Link to='/beginner'>
                        <BlueBtn className='w-fit px-2 md:px-3 bg-transparent  flex items-center py-0.5 bg-white text-gray rounded'> Beginners <BiLockAlt className='ml-1' /> </BlueBtn>
                    </Link>

                    <Link to='/intermediate' >
                        <BlueBtn className='w-fit px-2 md:px-3 bg-transparent  flex items-center py-0.5 bg-white text-gray rounded'> Intermediate <BiLockAlt className='ml-1' />   </BlueBtn>
                    </Link>

                    <Link to='/advanced'>
                        <BlueBtn className='w-fit px-2 md:px-3 bg-transparent border border-[#F97066] text-[#F97066] font-semibold  rounded py-0.5'> Advanced  </BlueBtn>
                    </Link>
                </div>

                <div className='hidden h-6 md:flex hover:cursor-pointer'>
                    <img src='./images/menuicon.png' alt='menuicon' />
                </div>
            </div>

            {isLoading &&
                <div>
                    <span className="loader"></span>
                </div>
            }

            <div className='flex flex-col-reverse lg:flex-row'>
                <div className='lg:w-[30%] 2xl:w-[20%]  text-sm  py-4   rounded-lg bg-white w-full mx-aut0 truncate mt-4 lg:ml-0'>
                    <p className='px-4 font-semibold'> Advanced Outline </p>

                    <div className='mt-4'>
                        {courses.map((item, index) => {

                            return (
                                <div key={index} onClick={() => {
                                    setSelectedItem(index)

                                }}  >

                                    <div className=' bg-[#F5F8FF]  p-2 flex items-center space-x-1  hover:cursor-pointer mb-1'>
                                        <div>
                                            <RiArrowDownSLine />
                                        </div>

                                        <div>
                                            <h1 className='truncate font-semibold w-[99%] md:w-full'> {item.courseName}</h1>
                                            <p className=' text-xs  flex items-center'> {item.lectures} <BsDot />  </p>
                                        </div>
                                    </div>

                                    {selectedItem === index && (
                                        <div className='ml-2 px-2 '>
                                            {item.lessons.map((lesson, index) => {
                                                if (lesson.lessonTitle) {
                                                    return (
                                                        <div key={index} className='flex flex-row items-center justify-between'>
                                                            <div className='mb-2 text-xs flex flex-row space-x-2 items-center'>
                                                                <AiOutlinePlayCircle className='text-xs' />
                                                                <p className='text-[0.9rem] text-xs  w-[10rem] truncate ' key={index}>{lesson.lessonTitle}</p>
                                                            </div>

                                                            <div>
                                                                <p className='text-xs'> 02:26 </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            }
                                            )}
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>

                <div className='w-full  md:pl-4'>
                    <div className='    rounded-md '>
                        <h1 className=' font-semibold p-4'>{courseTitle}</h1>

                        <div className='md:overflow-x-none flex md:flex-wrap overflow-x-auto   md:justify-between md:gap-2  gap-3 p-4 '>
                            {lessons.map((item, index) => (
                                <a key={index} href={`/view-course?id=${item.courseId}&lessonId=${item.lessonId}&category=advanced`}>
                                    <LevelCard className='bg-white'>
                                        <img src={'images/nfts.png'}
                                            alt='blockchain-image'
                                            className='rounded-t-xl  h-[8rem] w-full ' />

                                        <div className='px-4'>
                                            <p className='mt-4 font-bold  text-sm'> {item.lessonTitle} </p>
                                            <div className='flex justify-between items-center mt-3 '>
                                                {/* Conditionally set the background and text color based on item.level */}
                                                <p
                                                    className="px-1 text-[#F97066] py-1 text-xs rounded-full font-semibold flex  items-center"
                                                >
                                                    <FiBarChart className='mr-1' /> Advanced
                                                </p>
                                                <p className='flex items-center text-xs'> <span> <img className='mr-1' src='./images/loading.png' alt='icon-loading' /> </span> {item.length} mins </p>
                                            </div>
                                        </div>
                                    </LevelCard>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardContainer>

    )
}

export default Advanced
