import React from 'react'
import { DashboardContainer } from '../../layouts/DashboardContainer'
import { NavContainer } from '../../layouts/NavContainer'
import Navbar from '../../components/Navbar'
import {AiOutlineLeft} from 'react-icons/ai'
import { Inputs } from '../../layouts/Inputs'
import Checkbox from '../../components/Checkbox'
import { BlueBtn } from '../../layouts/BlueBtn'

const Cardcheckout = () => {
    return (
        <DashboardContainer>
            <NavContainer>
                <div>
                    <h1 className='font-bold text-2xl mt-4 hidden lg:flex'> Checkout  </h1>
                </div>

                <Navbar />
            </NavContainer>


            <div className='flex space-x-4 items-center md:mt-8 hover:cursor-pointer'>
                <AiOutlineLeft/>
                <h1> Back </h1>
            </div>

            <div className='bg-white rounded-md  p-4 lg:p-0 lg:bg-transparent mt-4 lg:mt-0'>
            <div className='mt-4 border-b border-gray pb-4'>
                <h1 className='font-semibold'> Payment method </h1>
                <p className='text-sm'> Fill in your billing details and address to continue. </p>
            </div>

            <div className='flex lg:flex-row flex-col lg:space-x-6 mt-2'>
               <div className='w-full lg:w-[18rem]'>
               <h1> Card details</h1>
               </div>

                <div className='flex flex-col mt-2 lg:mt-0'> 
                    <label> Name on Card  </label> 
                    <Inputs className='py-1' placeholder='Orezi Mena'/>
                </div>

                <div className='flex flex-col mt-2 lg:mt-0 '> 
                    <label> Expiry </label> 
                    <Inputs className='w-[6rem] py-1' placeholder='06/2024'/>
                </div>
            </div>

            <div className='  flex lg:flex-row flex-col lg:space-x-6 mt-4 border-b border-gray pb-4'>
               <div className='lg:w-[18rem] w-full'>
               
               </div>

                <div className='flex flex-col '> 
                    <label> Card number  </label> 
                    <Inputs className='py-1' placeholder='1234 1234 1234 1234'/> 
                </div>

                <div className='flex flex-col  mt-2 lg:mt-0'> 
                    <label> cvv </label> 
                    <Inputs className='w-[6rem] py-1' placeholder='•••'/>
                </div>
            </div>

            <div className='flex lg:flex-row flex-col lg:space-x-6 lg:mt-2 mt-4 border-b border-gray pb-4'>
                <div className='lg:w-[18rem] w-full'>
                    <h1> Email address</h1>
                    <p className='text-sm'> Invoices will be sent to this email address.</p>
                </div>

                <div>
                  <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='menamena@mail.com'/> 
                  <p className='text-sm mt-2'> + Add another </p>
                </div>
            </div>

            <div className='flex lg:flex-row flex-col lg:space-x-6 lg:mt-2 mt-4 border-b border-gray pb-4'>
                <div className='lg:w-[18rem] w-full'>
                    <h1> Street address</h1>
                </div>

                <div>
                  <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='100 Smith Street'/> 
                  
                </div>
            </div>

            <div className='flex lg:flex-row flex-col lg:space-x-6 lg:mt-2 mt-4 border-b border-gray pb-4'>
                <div className='lg:w-[18rem] w-full'>
                    <h1> City</h1>
                </div>

                <div>
                  <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='Email Collingwood'/> 
                  
                </div>
            </div>

            <div className='flex lg:flex-row flex-col lg:space-x-6 lg:mt-2 mt-4 border-b border-gray pb-4'>
                <div className='lg:w-[18rem] w-full'>
                    <h1> State/Province</h1>
                </div>

                <div className='flex flex-row justify-between w-full lg:w-[21rem]  '>
                  <Inputs className='py-1  w-[45%] lg:w-[10rem]' placeholder='vic'/> 

                  <Inputs className='py-1 w-[45%] lg:w-[10rem]' placeholder='3006'/> 
                  
                </div>
            </div>

            <div className='flex lg:flex-row flex-col lg:space-x-6 lg:mt-2 mt-4 '>
                <div className='lg:w-[18rem] w-full'>
                    <h1> Country</h1>
                </div>

                <div>
                  <Inputs className='py-1 w-full lg:w-[21rem]' placeholder='Australia'/> 
                  <div className='flex space-x-2 mt-2'>
                    <Checkbox/> 
                    <p className='text-sm'> Billing address same as contact address</p>
                  </div>

                 <div className='flex w-full lg:w-[21rem] justify-between mt-2'>
                 <BlueBtn className='w-[45%] lg:w-[10rem] py-1 bg-white border border-gray text-sm text-black'> Cancel </BlueBtn>
                  <BlueBtn className='w-[45%] lg:w-[10rem] py-1 text-sm'> Proceed </BlueBtn>
                 </div>
                  
                </div>
            </div>
            </div>
        </DashboardContainer>


    )
}

export default Cardcheckout
