import tw from "tailwind-styled-components"


export const TopCard = tw.div `

md:w-[13rem] 
lg:md:w-[14rem] 
w-[8rem]  
md:px-6 
px-1 
py-3 
md:py-3
rounded-lg
shadow
mt-4
hover:cursor-pointer
hover:shadow-md
bg-white
`