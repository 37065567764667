import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RiCloseFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { BsPersonCircle } from "react-icons/bs";

const QuizNav = () => {

  const userId = useSelector((e) => {
    if (e.userAuth.userAuth) {
      return e.userAuth.userAuth.userId
    }
  })

  const accessToken = useSelector((state) => {
    if (state.userAuth.userAuth) {
      return state.userAuth.userAuth.accessToken
    }
  });

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const [userImage, setUserImage] = useState(null)
  const [loading, setLoading] = useState(true);
  const [userPoints, setUserPoints] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const getUserPoints = async () => {

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/userPoints/${userId}`, {
        method: "GET",
        headers,
      });

      const response = await request.json();

      if (response.feedback == "success") {
        setUserPoints(response.data)
      }

    } catch (e) {
      console.log(e)
    }

  }

  const getUserImage = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/userImage/${userId}`, {
        method: 'GET',
        headers,
      });
      const response = await res.json();
      setUserImage(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserImage();
    getUserPoints();
  }, [])

  return (
    <div>
      <div className='flex flex-row-reverse lg:flex-row justify-between  items-center border-b border-gray pb-4 md:px-10
    px-6 pt-4'>
        <Link to='/dashboard' className='hover:cursor-pointer'>
          <div>
            <p className='text-xs underline flex items-center font-semibold '> <span> <RiCloseFill className='font-bold text-xl mr-2' /> </span> Close </p>
          </div>

        </Link>
        <div className=' justify-end   h-fit items-center  space-x-5 hidden lg:flex'>
          <div className='flex items-center space-x-2'>
            <img src='./images/achieve.png' alt='icons' />
              <h1>{userPoints ? `${userPoints}Pts` : "..."} </h1>
          </div>
          {!loading && !userImage &&
          <BsPersonCircle style={{ fontSize: 40, color: "blue" }} />
        }
        {!loading && userImage &&
          <img className=' h-8 w-8' src={userImage} alt='' style={{ borderRadius: '50%' }} />
        }
        </div>


      </div>

      <Link to='/dashboard'>
        <div className=' lg:hidden relative -top-[2.7rem]  flex flex-row  ml-5  space-x-1   items-center'>
          <img src='./images/logo.png' alt='logo'
            className='h-8' />
          <h1 className='text-black text-xs font-semibold '> Moon <br /> Republic </h1>


        </div>
      </Link >
    </div>
  )
}

export default QuizNav
