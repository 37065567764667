import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../layouts/Container';
import { Card } from '../../layouts/Card'
import { Inputs } from '../../layouts/Inputs';
import { BlueBtn } from '../../layouts/BlueBtn';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const EmailVerification = () => {

  const email = useSelector((state) => state.user.email);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const navigate = useNavigate();

  const resendVerificatioCode = async () => {

    setIsLoading(true);

    const request = await fetch(`${process.env.REACT_APP_API_URL}/resendVerificationCode?email=${email}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

    const response = await request.json();

    console.log(response);

    setIsLoading(false);

    if (Object.keys(response).length > 0) {

      if (response.feedback == "success") {

        toast.success("Verification code has been resent successfully", {
          position: "top-center"
        })

      } else if (response.feedback == "completed") {

        toast.success("Registration completed", {
          position: "top-center"
        })

        navigate('/signin');
        return;

      }else if (response.feedback == "verified") {

        toast.success("Email already verified", {
          position: "top-center"
        })

        navigate('/finalizing-setup');
        return;

      }
      else {

        toast.error("An error occurred!", {
          position: "top-center"
        })

      }

    } else {

      toast.success("An error occurred", {
        position: "top-center"
      })

    }

  }

  const verifyEmail = async () => {

    if (!verificationCode) {
      toast.error('Verification code is required',
        {
          position: "top-center"
        });
      return;
    }

    setIsLoading(true);

    const request = await fetch(`${process.env.REACT_APP_API_URL}/verifyEmail?email=${email}&code=${verificationCode}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

    const response = await request.json();

    console.log(response);

    setIsLoading(false);

    if (Object.keys(response).length > 0) {

      if (response.feedback == "success") {

        toast.success("Your email has been verified successfully!", {
          position: "top-center"
        })

        navigate('/finalizing-setup');
        return;

      } else if (response.feedback == "completed") {

        toast.success("Registration completed", {
          position: "top-center"
        })

        navigate('/signin');
        return;

      }else if (response.feedback == "verified") {

        toast.success("Email already verified", {
          position: "top-center"
        })

        navigate('/finalizing-setup');
        return;

      } else {

        toast.error(`${response.message}`, {
          position: "top-center"
        })

      }

    } else {

      toast.success("An error occurred", {
        position: "top-center"
      })

    }

  }

  useEffect(() => {

  }, [])

  return (
    <Container>
      <Card>
        <div className=''>

          <div className='text-center'>
            <img src='./images/logo.png' alt='logo'
              className='w-[2.3rem] mx-auto pt-8' />
            <h1 className='font-bold text-2xl'> Email Verification </h1>
            <p className='text-[0.7rem] text-center'>
              A verification code was sent to your email {email}. Check your email and enter code

            </p>
          </div>

          <div>
            <form className='text-sm md:text-xs flex flex-col mt-8'>
              <label> Verification Code </label>
              <Inputs placeholder='Code'
                onChange={(e) => {
                  setVerificationCode(e.target.value)
                }}
                type='text' />
            </form>
          </div>


          <BlueBtn className='mt-6 text-sm md:text-xs' onClick={() => {
            verifyEmail();
          }}>
            {isLoading &&
              <>Verifying...</>
            }
            {!isLoading &&
              <>Verify</>
            }

          </BlueBtn>

          <p className='mt-2 text-center text-sm md:text-xs pb-8'> <a href="#" onClick={() => {
            if (!isLoading) {
              resendVerificatioCode();
            }
          }}>
            {isLoading &&
              <span>Sending code...</span>
            }
            {!isLoading &&
              <span>Resend verification code</span>
            }
          </a>
          </p>

        </div>
      </Card>
    </Container>
  )
}

export default EmailVerification

