import tw from "tailwind-styled-components"


export const LevelCard = tw.div `
min-w-[13rem]   
w-[13rem]
rounded-md
shadow
pb-4
hover:cursor-pointer
hover:shadow-md

`