import tw from "tailwind-styled-components"


export const Button = tw.div`
 ${({ color }) => `
    background-color: ${color};
    hover:bg-${color};
  `}
bg-
py-0.5
rounded-full 
w-fit
px-2
text-xs
hover:cursor-pointer
`;


