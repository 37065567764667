import React, { useState } from 'react';
import { BlueBtn } from '../layouts/BlueBtn';

const Modal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {/* Modal Button */}
            <BlueBtn className="w-fit px-4 py-1 text-xs" onClick={toggleModal}>
                Proceed
            </BlueBtn>

            {/* Modal */}
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    {/* Blurred Background */}
                    <div
                        className="fixed inset-0 backdrop-blur-lg bg-white/30"
                        onClick={toggleModal}
                    />

                    <div className="bg-white p-6 rounded shadow-lg relative w-[24rem]">
                        <div>
                            <img src='./images/modalicon.png' alt=''
                                className='w-10 h-10 mx-auto' />
                            <h1 className='mt-4 font-bold text-sm text-center'> Upgrade Account </h1>
                            <p className='text-center text-sm font-semibold'> You need to upgrade your account to access
                                courses in Advanced. Proceed to upgrade? </p>
                        </div>
                        <div className='flex justify-center space-x-4 mt-4'>
                            <BlueBtn
                                className="w-fit px-8 bg-transparent text-black py-2 text-xs border-gray"
                                onClick={toggleModal}
                            >
                                Cancel
                            </BlueBtn>
                            <BlueBtn className="w-fit px-8 py-2 text-xs" >
                                Proceed
                            </BlueBtn>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
