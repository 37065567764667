import React, { useState, useEffect, StyleSheet } from 'react'
import { BlueBtn } from '../../layouts/BlueBtn'
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineLeft } from 'react-icons/ai'
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { current } from '@reduxjs/toolkit';

const Plan = () => {

    const query = new URLSearchParams(window.location.search);

    const dispatch = useDispatch();

    const userId = useSelector((e) => {
        if (e.userAuth.userAuth) {
            return e.userAuth.userAuth.userId
        }
    })

    const accessToken = useSelector((state) => {
        if (state.userAuth.userAuth) {
            return state.userAuth.userAuth.accessToken
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentPlan, setCurrentPlan] = useState({ id: '' });
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ id: '', name: '', price: '', yearlyPrice: '' });
    const [subscriptionPeriod, setSubscriptionPeriod] = useState('Monthly');
    const [nextDate, setNextDate] = useState('');
    const [choosePaymentMethod, setChoosePaymentMethod] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('card');
    const [isVerifying, setIsVerifying] = useState(false);

    let yearlyButton = 'text-primaryblue';

    let monthlyButton = '';

    if (subscriptionPeriod == 'Monthly') {

        yearlyButton = '';

        monthlyButton = 'text-primaryblue';

    }

    const getUserPlan = async () => {

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/userPlans/${userId}`, {
                method: 'GET',
                headers,
            });

            const response = await request.json();

            if (response.message == "token_expired") {
                dispatch({ type: 'LOGOUT' });
            }
        
            if (response.feedback == "success") {

                if (response.data.length > 0) {
                    setCurrentPlan({ id: response.data[0].subscriptionPlanId });
                }

                setIsLoading(false);
            }

        } catch (e) {
            setIsLoading(false);
        }

    }

    const getSubscriptionPlans = async () => {

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/subscriptionPlans`, {
                method: 'GET',
                headers,
            });

            const response = await request.json();

            if (response.message == "token_expired") {
                dispatch({ type: 'LOGOUT' });
            }

            if (response.feedback == "success") {

                if (response.data.length > 0) {

                    setSubscriptionPlans(response.data);

                    getUserPlan();
                }

                setIsLoading(false);
            }

        } catch (e) {
            setIsLoading(false);
        }

    }

    const calculateNextDate = () => {

        let numberOfDays = 365;

        if (subscriptionPeriod == "Monthly") {
            numberOfDays = 30;
        }

        const today = new Date();
        const nextDate = new Date();
        nextDate.setDate(today.getDate() + numberOfDays);

        // Formatting the date as "Month, Day Year"
        const formattedNextDate = nextDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });


        setNextDate(formattedNextDate);
    };

    useEffect(() => {

        if (query.get("success")) {
            toast.success("We are verifying your payment!", {
                position: 'top-center'
            });
            const invoiceId = query.get('invoiceId');
            checkPaymentStatus(invoiceId);
            return;
        }

        if (query.get("canceled")) {
            toast.error("Your payment was canceled!", {
                position: 'top-center'
            });
        }
        getSubscriptionPlans();
        return () => {
            clearTimeout(checkPaymentStatus);
        };
    }, [])

    const [paymentStatus, setPaymentStatus] = useState(false);

    const checkPaymentStatus = async (invoiceId) => {
        if (!paymentStatus) {
            // Your code here
            console.log('Recursive function is running...');

            // Perform the API fetch request
            try {
                const request = await fetch(`${process.env.REACT_APP_API_URL}/checkPayment/${invoiceId}`, {
                    method: "GET",
                    headers,
                });

                const response = await request.json();
                console.log(response)
                if (response.feedback == "paid") {
                    setPaymentStatus(true);
                    setIsLoading(false);
                    return;
                }

            } catch (error) {
                console.error('API fetch error:', error);
            }

            // Set the interval to run the function again in 1 minute (60,000 milliseconds)
            setTimeout(checkPaymentStatus, 60000);
        }
    };

    useEffect(() => {
        calculateNextDate()
    }, [subscriptionPeriod])

    const proceedToPay = async () => {

        let endPoint = 'createInvoice';

        if (!selectedPlan.id) {
            toast.error('Select a plan to continue', {
                position: 'top-center'
            });
            return;
        }

        if (!paymentMethod) {
            toast.error('Choose a payment method to continue', {
                position: 'top-center'
            });
            return;
        }

        if(paymentMethod=="crypto"){
            /*toast.error('Payment method is unvailable, try other payment methods', {
                position: 'top-center'
            });
            return;*/
            endPoint = 'createCryptoInvoice';
        }

        setIsProcessing(true);

        let paymentType = "new";

        if (currentPlan.id != "" && currentPlan.id < selectedPlan.id) {
            paymentType = "upgrade"
        }
        if (currentPlan.id != "" && currentPlan.id > selectedPlan.id) {
            paymentType = "downgrade"
        }

        const postData = { userId, planId: selectedPlan.id, subscriptionPeriod, paymentMethod, paymentType };

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/${endPoint}`, {
                method: 'POST',
                body: JSON.stringify(postData),
                headers,
            });

            const response = await request.json();

            if (response.message == "token_expired") {
                dispatch({ type: 'LOGOUT' });
            }

            if (response.feedback == "success") {

                console.log(response.data)

                window.location.href = response.data.url;

                setIsLoading(false);
            }

        } catch (e) {
            setIsLoading(false);
        }


    }

    return (
        <>
            {isLoading &&
                <div>
                    <span className="loader"></span>
                </div>
            }
            {isProcessing &&
                <div>
                    <span className="loader"></span>
                </div>
            }

            {!isLoading && choosePaymentMethod == false && paymentStatus == true &&
                <div className='px-4 mt-10'>
                    <div className='flex space-x-2 justify-center mt-4 lg:mt-2'>
                        <BsCheckCircleFill style={{ fontSize: 50, color: "green" }} />
                    </div>

                    <div className="flex justify-center items-center">
                        <div className="w-2/4 bg-gray-300 p-4 text-center">
                            <p>
                                Your subscription has been activated successfully.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center">
                        <div className="w-2/4 bg-gray-300 p-4 text-center">
                            <a href="/beginner">
                                <button className="bg-primaryblue rounded-md text-white py-2 w-fit text-xs px-2"> Start learning </button>
                            </a>
                        </div>
                    </div>

                </div>
            }

            {!isLoading && choosePaymentMethod == false && paymentStatus == false &&
                <div className='px-4'>
                    <div className='flex space-x-2 justify-center mt-4 lg:mt-2'>
                        <button className={yearlyButton} onClick={() => { setSubscriptionPeriod('Yearly') }}> Yearly  </button>
                        <button className={monthlyButton} onClick={() => { setSubscriptionPeriod('Monthly') }}> Monthly  </button>
                    </div>

                    <div className='flex lg:flex-row flex-col justify-between border-b border-gray pb-4'>

                        {subscriptionPlans.map((row) => {

                            let boxStyle = 'border-2 border-gray h-[13rem] lg:w-[16rem] w-full mx-auto mt-4 rounded-lg p-4';

                            if (row.id == currentPlan.id || row.id == selectedPlan.id) {
                                boxStyle = 'border-2 border-primaryblue shadow-lg h-[13rem] lg:w-[16rem] w-full mx-auto mt-4 rounded-lg p-4';
                            }

                            let price = row.price;
                            let periodTitle = 'month';

                            if (subscriptionPeriod == 'Yearly') {
                                price = row.yearlyPrice;
                                periodTitle = 'year';
                            }

                            let paymentButtonText = "Downgrade";

                            if (currentPlan.id != "" && currentPlan.id < row.id) {
                                paymentButtonText = "Upgrade"
                            }
                            return (
                                <div key={row.id} className={[boxStyle]} onClick={() => {
                                    if (row.id == currentPlan.id) {
                                        toast.error('You are already subscribed to this plan!', {
                                            position: 'top-center'
                                        });
                                        return;
                                    }
                                    setSelectedPlan({ id: row.id, name: row.name, price: row.price, yearlyPrice: row.yearlyPrice });

                                }}>
                                    <div className='flex items-center space-x-2'>
                                        <h1> {row.name} </h1>
                                        {row.id == currentPlan.id &&
                                            <p className='px-2 bg-[#DDE7FF] text-primaryblue rounded-full text-sm '> Current plan </p>
                                        }
                                    </div>

                                    <div className='flex items-center  space-x-1'>
                                        <h1 className='font-bold text-2xl'> ${price} </h1>
                                        <p className='text-xs mt-2'>/{periodTitle} </p>
                                    </div>

                                    <div className=' h-[3rem] mt-4 text-sm'>
                                        <p className='text-xs'> {row.description} </p>
                                    </div>
                                    {row.id == currentPlan.id &&
                                        <BlueBtn className='mt-4 text-xs bg-transparent border border-gray text-black'> Cancel plan </BlueBtn>
                                    }

                                    {currentPlan.id != '' && currentPlan.id != row.id &&
                                        <BlueBtn className='mt-4  text-xs '> {paymentButtonText} </BlueBtn>
                                    }
                                </div>
                            )

                        })}

                    </div>

                    <div className='flex lg:flex-row flex-col  justify-between mt-4'>
                        {selectedPlan.id != "" &&
                            <>
                                <div>
                                    <h1> Order summary </h1>
                                    <p> {selectedPlan.name} - 1 item(s) </p>
                                </div>

                                <div className='lg:w-[70%] w-full mt-4 lg:mt-0 rounded-md border border-gray p-4'>
                                    <div className='flex  border-b border-gray pb-4 justify-between  '>
                                        <div className='flex flex-col '>
                                            <h1> {selectedPlan.name}</h1>
                                            <h1> {subscriptionPeriod == "Yearly" ? <>Annual subscription</> : <>Monthly Subscription</>}</h1>
                                        </div>

                                        <h1>
                                            {subscriptionPeriod == "Yearly" ? <>${selectedPlan.yearlyPrice}</> :
                                                <>${selectedPlan.price}</>}
                                        </h1>
                                    </div>

                                    <div className='border-b border-gray  '>
                                        <h1 className='text-blue font-semibold mt-2 text-blue-600 text-sm'> Add coupon code </h1>
                                        <div className='flex  justify-between font-bold mt-2'>
                                            <h1> Total </h1>
                                            <h1>
                                                {subscriptionPeriod == "Yearly" ? <>${selectedPlan.yearlyPrice}</> :
                                                    <>${selectedPlan.price}</>}
                                            </h1>
                                        </div>
                                    </div>

                                    <div className=' border-gray '>
                                        <h1 className='text-blue mt-2'> Total billed  {subscriptionPeriod == "Yearly" ? <>annually</> : <>monthly</>} </h1>
                                        <div className='flex  justify-between'>
                                            <p className='italic text-sm'> Next due charge on {nextDate} </p>
                                            <h1>
                                                {subscriptionPeriod == "Yearly" ? <>${selectedPlan.yearlyPrice}</> :
                                                    <>${selectedPlan.price}</>}
                                            </h1>
                                        </div>

                                        <p className='mt-4 text-sm'> Based on the billing information you have provided,
                                            your purchase may be subject to local taxes.
                                            The final charge may be different than the
                                            amount shown here and will be displayed
                                            on your invoice.</p>
                                    </div>

                                    <BlueBtn className='mt-4' onClick={() => {
                                        setChoosePaymentMethod(true)
                                    }}> Proceed </BlueBtn>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }

            {!isLoading && choosePaymentMethod == true &&
                <div className='flex lg:flex-row lg:justify-between  flex-col lg:mt-0 bg-white px-4 py-1 rounded-md '>
                    <div className='lg:w-[49%] text-graytext'>
                        <Link onClick={() => { setChoosePaymentMethod(false) }}>
                            <div className='flex space-x-2 text-sm font-semibold items-center underline  mt-8 hover:cursor-pointer'>
                                <AiOutlineLeft />
                                <h1 className=''> Back </h1>
                            </div>

                        </Link>
                        <div className='mt-6'>
                            <h1 className='font-semibold text-black'> Select payment method</h1>
                            <p className='text-sm '> Select a payment method to continue your account upgrade </p>
                        </div>

                        <div className='mt-6'>
                            <h1 className='text-sm font-semibold'> Payment method</h1>
                            <p className='text-sm '> Make payments using your credit card, crypto asset or via binance pay. </p>
                        </div>

                        <div onClick={() => {
                            setPaymentMethod('card');
                        }} className={`${paymentMethod == 'card' ? 'border-primaryblue' : 'border-gray hover:border-primaryblue'} flex flex-row items-center space-x-4 mt-4 border rounded-md px-4 py-2  hover:cursor-pointer`}>
                            <img src='./images/cards.png' className='h-6 w-6' alt='' />
                            <div>
                                <p className={`${paymentMethod == 'card' ? 'text-primaryblue' : ''} text-sm font-semibold`}> Credit/Debit cards  </p>
                                <p className='text-sm'>Pay with your debit or credit card</p>
                            </div>
                        </div>

                        <div onClick={() => {
                            setPaymentMethod('crypto');
                        }} className={`${paymentMethod == 'crypto' ? 'border-primaryblue' : 'border-gray hover:border-primaryblue'} flex flex-row items-center  space-x-4 mt-4 border rounded-md px-4 py-2 hover:cursor-pointer`}>
                            <img src='./images/cryptoassets.png' className='h-6 w-6' alt='' />
                            <div>
                                <p className={`${paymentMethod == 'crypto' ? 'text-primaryblue' : ''} text-sm font-semibold`}> Cypto Asset  </p>
                                <p className='text-sm'> Pay with crypto assets</p>
                            </div>
                        </div>
                        {/*
                        <div className='flex flex-row items-center space-x-4 mt-4 border rounded-md px-4 py-2 border-gray hover:cursor-pointer hover:border-primaryblue'>
                            <img src='./images/binancepay.png' className='h-6 w-6' alt='' />
                            <div>
                                <p className=' text-sm font-semibold'> Binance Pay  </p>
                                <p className='text-sm'> Lorem ipsum dolor sit amet consectetur. Nulla tellus tortor nibh</p>
                            </div>
                        </div>
            */}
                    </div>

                    <div className='lg:w-[49%] mt-10'>
                        <div className='text-sm'>
                            <h1> Order Summary </h1>
                            <p className='text-graytext'> {selectedPlan.name} - 1 item(s) </p>
                        </div>

                        <div className='border shadow mt-4 border-gray rounded-md'>
                            <div className='flex justify-between mt-2 px-4 text-sm flex-row'>
                                <div>
                                    <h1 className='font-semibold'> {selectedPlan.name} - 1 item(s) </h1>
                                    <p className='text-graytext'> {subscriptionPeriod == "Yearly" ? <>Annual subscription</> : <>Monthly Subscription</>} </p>
                                </div>

                                <div>
                                    <h1> {subscriptionPeriod == "Yearly" ? <>${selectedPlan.yearlyPrice}</> :
                                        <>${selectedPlan.price}</>} </h1>
                                </div>
                            </div>

                            <hr className='text-gray w-[95%] mt-5 mx-auto' />
                            {/*
                            <div className='mt-5'>
                                <h1 className='text-primaryblue text-sm ml-8 '> Add coupon code </h1>
        </div>*/}

                            <div className='flex mt-4 justify-between  px-4 font-bold'>
                                <h1> Total </h1>
                                <h1>   {subscriptionPeriod == "Yearly" ? <>${selectedPlan.yearlyPrice}</> :
                                    <>${selectedPlan.price}</>} </h1>
                            </div>

                            <hr className='text-gray w-[95%] mt-5 mx-auto' />

                            <div className='flex justify-between px-4 mt-6 text-sm flex-row'>
                                <div>
                                    <h1 className='font-semibold text-graytext'> Total billed  {subscriptionPeriod == "Yearly" ? <>annually</> : <>monthly</>} </h1>
                                    <p className='italic font-sm text-graytext'> Next due charge on {nextDate} </p>
                                </div>

                                <div className='text-sm'>
                                    <h1 className='text-graytext'>  {subscriptionPeriod == "Yearly" ? <>${selectedPlan.yearlyPrice}</> :
                                        <>${selectedPlan.price}</>} </h1>
                                </div>
                            </div>

                            <div className='px-4  text-sm mt-4'>
                                <p> Based on the billing information you have provided,
                                    your purchase may be subject to local taxes.
                                    The final charge may be different than the
                                    amount shown here and will be displayed
                                    on your invoice. </p>


                                <BlueBtn className='mt-4 mb-4'
                                    onClick={() => {
                                        proceedToPay();
                                    }}
                                    disabled={false}
                                > {isProcessing && 'Loading...' || 'Continue'} </BlueBtn>

                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

/*const styles = StyleSheet.create({
    subscriptionPlans: {

    }
})*/

export default Plan
